import React, { useEffect } from 'react'
import './ValidateEmail.css'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

export const ValidateEmail = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const emailToken = searchParams.get('emailToken')

  useEffect(() => {
    fetch(`/api/user/metadata/email/${emailToken}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(async (response) => {
      const user = await response.json()

      const formData = {
        email_confirmed: 'confirmed',
        email_token: 'confirmed_token',
      }

      fetch(`/api/user/metadata/${user}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      }).then(async (response) => {
        const data = await response.json()

        toast.success('Email confirmé avec succès ! Vous pouvez maintenant vous connecter.')
        navigate('/')
      })
    })
  }, [])

  return <div></div>
}

export default ValidateEmail
