import React, { useState, useEffect } from 'react'
import './Administration.css'
import { toast } from 'sonner'

export const Administration = () => {
  const [data, setData] = useState(null)
  const [cryptoList, setCryptoList] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/low-caps')
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const jsonData = await response.json()
        setData(jsonData)

        if (jsonData && jsonData.cryptos_low_caps) {
          const cryptoValues = Object.values(jsonData.cryptos_low_caps)
          const formattedList = cryptoValues.join(',')
          setCryptoList(formattedList)
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [])

  const handleSaveClick = async () => {
    try {
      const updatedCryptoList = cryptoList.split(',').map((crypto) => crypto.trim())

      const updatedData = { ...data, cryptos_low_caps: updatedCryptoList }

      const response = await fetch('/api/low-caps', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      })

      if (!response.ok) {
        throw new Error('Failed to update data')
      }

      setData(updatedData)
      toast.success('Cryptomonnaies mises à jour !')
    } catch (error) {
      console.error('Error updating data:', error)
    }
  }

  const handleTextAreaChange = (event) => {
    setCryptoList(event.target.value)
  }

  return (
    <div className="administration">
      <div className="container">
        <p className="title">Administration</p>
        <div className="crypto-low-caps">
          <p className="title-cryptos-low-caps">Cryptos Low Caps</p>
          <p className="description">
            Pour mettre à jour la liste insérez la liste séparer par des virgules (,), sans espace et en utilisant leur
            sigle (Ex: pour Bitcoin utilisez BTC) <b>PROVENANT DE COINMARKETCAP !!!</b>
          </p>
          <textarea
            onChange={handleTextAreaChange}
            className="cryptos-low-caps-area"
            name="cryptos-low-caps-area"
            placeholder="Liste des low caps"
            value={cryptoList}
          ></textarea>
        </div>
        <button onClick={handleSaveClick} id="submit-low-caps" className="submit-low-caps">
          Sauvegarder
        </button>
      </div>
    </div>
  )
}

export default Administration
