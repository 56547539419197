import React from 'react'
import TopNavbarDashboard from '../../TopNavbarDashboard/TopNavbarDashboard'
import './TutorialPages.css'

const Tutorial1 = ({ handleIndexChange }) => {
  return (
    <div className="landing-page-dashboard">
      <TopNavbarDashboard text={'Tutoriels'} handleIndexChange={handleIndexChange} />
      <div className="landing-page-tutorial">
        <h2>Introduction aux Cryptomonnaies</h2>

        <h3>Introduction</h3>
        <p>
          Les cryptomonnaies sont des monnaies numériques ou virtuelles qui utilisent la cryptographie pour sécuriser
          les transactions. Contrairement aux monnaies traditionnelles, elles ne sont pas émises par une autorité
          centrale, rendant les transactions potentiellement anonymes et décentralisées.
        </p>

        <h3>Historique</h3>
        <p>
          Le concept de cryptomonnaie remonte à la fin des années 1980, mais c'est avec l'introduction de Bitcoin en
          2009 par une personne ou un groupe sous le pseudonyme de Satoshi Nakamoto que les cryptomonnaies ont
          réellement pris leur essor.
        </p>

        <h3>Fonctionnement</h3>
        <ul>
          <li>
            <strong>Blockchain :</strong> Une blockchain est un registre distribué qui enregistre toutes les
            transactions effectuées avec une cryptomonnaie. Chaque bloc de la chaîne contient plusieurs transactions et
            une référence au bloc précédent, formant ainsi une chaîne.
          </li>
          <li>
            <strong>Minage :</strong> Le minage est le processus par lequel les transactions sont vérifiées et ajoutées
            à la blockchain. Les mineurs résolvent des problèmes mathématiques complexes pour valider les transactions,
            et en retour, ils reçoivent des cryptomonnaies comme récompense.
          </li>
          <li>
            <strong>Portefeuilles (Wallets) :</strong> Pour stocker et utiliser des cryptomonnaies, vous avez besoin
            d'un portefeuille numérique. Les portefeuilles peuvent être matériels (physiques) ou logiciels
            (applications).
          </li>
        </ul>

        <h3>Avantages et Inconvénients</h3>
        <ul>
          <li>
            <strong>Avantages :</strong>
            <ul>
              <li>Transactions rapides et sécurisées.</li>
              <li>Frais de transaction réduits.</li>
              <li>Anonymat et confidentialité.</li>
              <li>Accessibilité mondiale.</li>
            </ul>
          </li>
          <li>
            <strong>Inconvénients :</strong>
            <ul>
              <li>Volatilité des prix.</li>
              <li>Utilisation potentielle pour des activités illégales.</li>
              <li>Complexité technique pour les novices.</li>
              <li>Risques de sécurité (hacks, escroqueries).</li>
            </ul>
          </li>
        </ul>

        <h3>Principales Cryptomonnaies</h3>
        <ul>
          <li>
            <strong>Bitcoin (BTC) :</strong> La première et la plus connue des cryptomonnaies. Elle est souvent appelée
            "l'or numérique".
          </li>
          <li>
            <strong>Ethereum (ETH) :</strong> Connu pour ses contrats intelligents et ses applications décentralisées
            (dApps).
          </li>
          <li>
            <strong>Ripple (XRP) :</strong> Axé sur les paiements internationaux et la réduction des frais de
            transaction.
          </li>
          <li>
            <strong>Litecoin (LTC) :</strong> Conçu pour être plus rapide et plus léger que Bitcoin.
          </li>
        </ul>

        <h3>Comment Acheter des Cryptomonnaies</h3>
        <ol>
          <li>
            <strong>Choisir une plateforme d'échange :</strong> Recherchez des plateformes réputées comme Coinbase,
            Binance ou Kraken.
          </li>
          <li>
            <strong>Créer un compte :</strong> Inscrivez-vous sur la plateforme choisie et vérifiez votre identité.
          </li>
          <li>
            <strong>Déposer des fonds :</strong> Utilisez un virement bancaire, une carte de crédit ou une autre méthode
            de paiement pour déposer des fonds sur votre compte.
          </li>
          <li>
            <strong>Acheter des cryptomonnaies :</strong> Choisissez la cryptomonnaie que vous souhaitez acheter et
            passez votre commande.
          </li>
        </ol>

        <h3>Sécurité et Stockage</h3>
        <ul>
          <li>
            Utilisez des portefeuilles sécurisés : Les portefeuilles matériels comme Ledger ou Trezor offrent une
            sécurité supplémentaire.
          </li>
          <li>
            Activez l'authentification à deux facteurs (2FA) : Ajoutez une couche de sécurité supplémentaire à vos
            comptes.
          </li>
          <li>
            Soyez vigilant aux arnaques : Méfiez-vous des promesses de rendements élevés et des offres non sollicitées.
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Tutorial1
