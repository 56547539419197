import React, { useEffect } from 'react'
import './Advantages.css'
import { RxCross2 } from 'react-icons/rx'
import { FaCheck } from 'react-icons/fa6'
import Aos from 'aos'
import 'aos/dist/aos.css'

export const Advantages = () => {
  useEffect(() => {
    Aos.init()
  }, [])

  return (
    <div className="advantages" data-aos="fade-up">
      <div className="advantages-without">
        <p>Sans CoinFinder</p>
        <ul>
          <li>
            <span>
              <RxCross2 />
            </span>
            Pas de Suivi en Temps Réel : Difficile de rester à jour.
          </li>
          <li>
            <span>
              <RxCross2 />
            </span>
            Sécurité Incertaine : Risque d'investir dans des cryptomonnaies non vérifiées.
          </li>
          <li>
            <span>
              <RxCross2 />
            </span>
            Pas de Sélections d'Experts : Aucune liste exclusive de low caps sélectionnées.
          </li>
          <li>
            <span>
              <RxCross2 />
            </span>
            Manque de Personnalisation : Impossible de suivre facilement vos cryptomonnaies préférées.
          </li>
          <li>
            <span>
              <RxCross2 />
            </span>
            Isolation : Pas de communauté pour échanger des idées.
          </li>
          <li>
            <span>
              <RxCross2 />
            </span>
            Informations Limitées : Accès restreint à des détails fiables sur les cryptomonnaies.
          </li>
        </ul>
      </div>
      <div className="advantages-with">
        <p>Avec CoinFinder</p>
        <ul>
          <li>
            <span>
              <FaCheck />
            </span>
            Analyse en Temps Réel : Suivez les tendances du marché instantanément.
          </li>
          <li>
            <span>
              <FaCheck />
            </span>
            Sécurité et Vérification : Investissez en toute confiance avec des cryptomonnaies vérifiées.
          </li>
          <li>
            <span>
              <FaCheck />
            </span>
            Sélections d'Experts : Accédez à une liste exclusive de 5 low caps sélectionnées par nos experts.
          </li>
          <li>
            <span>
              <FaCheck />
            </span>
            Tableau de Bord Personnalisé : Suivez et recevez des alertes pour vos cryptomonnaies préférées.
          </li>
          <li>
            <span>
              <FaCheck />
            </span>
            Communauté Active : Partagez et discutez avec d'autres passionnés.
          </li>
          <li>
            <span>
              <FaCheck />
            </span>
            Informations Complètes : Fiches détaillées pour des décisions éclairées.
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Advantages
