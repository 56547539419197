import React, { useRef, useState } from 'react'
import './SignUp.css'
import validator from 'validator'
import { toast } from 'sonner'
import CryptoJS from 'crypto-js'

export const SignUp = ({ onClose, onLogIn }) => {
  const modalRef = useRef()
  const [creationUserError, setCreationUserError] = useState('')
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    passwordConfirm: '',
  })
  const [formValidationError, setValidationError] = useState(formData)

  function generateRandomBytes(length) {
    const randomBytes = CryptoJS.lib.WordArray.random(length)
    return randomBytes.toString(CryptoJS.enc.Hex)
  }

  async function addUser() {
    setCreationUserError('')

    fetch('/api/user/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then(async (response) => {
        const data = await response.json()

        if (data.error) {
          toast.error(data.error)
          return
        }

        const userId = data.ID
        const formToken = {
          email_token: generateRandomBytes(64),
        }

        fetch(`/api/user/metadata/${userId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formToken),
        }).then(async (response) => {
          const data = await response.json()

          const emailData = {
            to: formData.email,
            subject: 'CoinFinder - Confirmation de compte',
            text: `Bonjour ${formData.username},\n\nMerci de vous être inscrit sur CoinFinder !\n\nPour confirmer votre compte, veuillez cliquer sur le lien suivant : https://coinfinder.fr/validate-email?emailToken=${formToken.email_token}\n\nSi vous n'êtes pas à l'origine de cette inscription, veuillez ignorer cet email.\n\nCordialement,\nL'équipe CoinFinder`,
          }

          fetch('/api/send-email', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(emailData),
          }).then((response) => {
            if (response.ok) {
              return response.json()
            } else {
              throw new Error('Erreur lors de la requête')
            }
          })
        })

        toast.success(
          'Compte créé ! Un email à été envoyé à ' +
            formData.email +
            ' ! Veuillez confirmer votre compte avant de vous connecter.'
        )
        onClose()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      onClose()
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const validationErrors = {}

    if (formData.username === null || formData.username === '') {
      validationErrors.username = 'Pseudonyme est requis'
      toast.error('Pseudonyme requis')
      return
    }

    if (formData.email === null || formData.email === '') {
      validationErrors.email = 'Email est requis'
      toast.error('Email requis')
      return
    } else if (!validator.isEmail(formData.email)) {
      validationErrors.email = 'Format incorrect'
      toast.error("Format d'email incorrect")
      return
    }

    if (!formData.password === null || formData.password === '') {
      toast.error('Mot de passe requis')
      validationErrors.password = 'Mot de passe requis'
      return
    }

    if (
      formData.passwordConfirm === null ||
      formData.passwordConfirm === '' ||
      formData.password !== formData.passwordConfirm
    ) {
      validationErrors.passwordConfirm = 'Mots de passe ne correspondent pas'
      toast.error('Les mots de passe ne correspondent pas')
      return
    }

    if (
      !formValidationError.username &&
      !formValidationError.email &&
      !formValidationError.password &&
      !formValidationError.passwordConfirm
    ) {
      addUser(formData)
    }
  }

  return (
    <div ref={modalRef} onClick={closeModal} className="login-modal">
      <form id="signup-form" onSubmit={handleSubmit}>
        <div className="modal-login">
          <div className="modal-state-button">
            <a onClick={onLogIn} className="signup-login-button">
              Se connecter
            </a>
            <a className="signup-signup-button">S'inscrire</a>
          </div>
          <div className="pseudonyme-login">
            <label htmlFor="pseudo-signup-input">Pseudo</label>
            <input
              id="pseudo-signup-input"
              name="username"
              type="text"
              placeholder="Pseudonyme"
              onChange={handleChange}
            ></input>
            {formValidationError.username && <span className="error-validation">{formValidationError.username}</span>}
          </div>
          <div className="email-adresse-login">
            <label htmlFor="email-signup-input">Adresse Email</label>
            <input
              id="email-signup-input"
              name="email"
              type="text"
              placeholder="Adresse Email"
              onChange={handleChange}
            ></input>
          </div>
          <div className="password-login">
            <label htmlFor="password-signup-input">Mot de passe</label>
            <input
              id="password-signup-input"
              name="password"
              type="password"
              placeholder="Mot de passe"
              onChange={handleChange}
            ></input>
          </div>
          <div className="password-confirm-login">
            <label htmlFor="password-confirm-signup-input">Confirmer Mot de passe</label>
            <input
              id="password-confirm-signup-input"
              name="passwordConfirm"
              type="password"
              placeholder="Confirmer Mot de passe"
              onChange={handleChange}
            ></input>
          </div>
          <p className="signup-login">
            Déjà un compte ? <a onClick={onLogIn}>Se connecter</a>
          </p>
          <p className="accept-signup">En cliquant sur 'S'inscrire' vous acceptez les conditions d'utilisation.</p>
          <button type="submit">S'inscrire</button>
        </div>
      </form>
    </div>
  )
}
