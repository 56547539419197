import React from 'react'
import TopNavbarDashboard from '../../TopNavbarDashboard/TopNavbarDashboard'
import './TutorialPages.css'

const Tutorial2 = ({ handleIndexChange }) => {
  return (
    <div className="landing-page-dashboard">
      <TopNavbarDashboard text={'Tutoriels'} handleIndexChange={handleIndexChange} />
      <div className="landing-page-tutorial">
        <h2>Comment Miner des Cryptomonnaies</h2>

        <h3>Introduction au Minage</h3>
        <p>
          Le minage de cryptomonnaies est le processus de vérification des transactions sur la blockchain et d'ajout de
          nouveaux blocs. Les mineurs utilisent leur puissance de calcul pour résoudre des problèmes mathématiques
          complexes, et en récompense, ils reçoivent des cryptomonnaies.
        </p>

        <h3>Équipement Nécessaire</h3>
        <ul>
          <li>
            <strong>Matériel de Minage :</strong>
            <ul>
              <li>
                <strong>ASICs (Application-Specific Integrated Circuits) :</strong> Conçus spécifiquement pour le
                minage, ils sont plus efficaces mais aussi plus chers.
              </li>
              <li>
                <strong>GPUs (Graphics Processing Units) :</strong> Utilisés principalement pour le minage d'altcoins
                comme Ethereum.
              </li>
              <li>
                <strong>CPUs (Central Processing Units) :</strong> Moins efficaces, mais peuvent être utilisés pour
                certaines cryptomonnaies.
              </li>
            </ul>
          </li>
          <li>
            <strong>Logiciel de Minage :</strong>
            <ul>
              <li>
                <strong>CGMiner :</strong> Un des logiciels les plus populaires pour le minage de Bitcoin.
              </li>
              <li>
                <strong>Claymore :</strong> Spécialement conçu pour le minage d'Ethereum.
              </li>
              <li>
                <strong>NiceHash :</strong> Un logiciel simple d'utilisation pour miner différentes cryptomonnaies.
              </li>
            </ul>
          </li>
          <li>
            <strong>Portefeuille :</strong> Un portefeuille sécurisé pour recevoir les récompenses de minage.
          </li>
        </ul>

        <h3>Choisir une Cryptomonnaie à Miner</h3>
        <p>
          Certaines cryptomonnaies sont plus rentables à miner que d'autres en fonction de la difficulté de minage, du
          prix de la cryptomonnaie et de votre équipement. Bitcoin, Ethereum, Litecoin et Monero sont quelques-unes des
          options populaires.
        </p>

        <h3>Processus de Minage</h3>
        <ol>
          <li>
            <strong>Configurer l'équipement :</strong> Installez et configurez votre matériel et logiciel de minage.
          </li>
          <li>
            <strong>Rejoindre un Pool de Minage :</strong> Les pools de minage permettent aux mineurs de combiner leurs
            ressources pour augmenter leurs chances de résoudre les blocs et partager les récompenses.
          </li>
          <li>
            <strong>Démarrer le Minage :</strong> Lancez le logiciel de minage et commencez à miner. Surveillez
            régulièrement les performances et les températures de votre matériel.
          </li>
        </ol>

        <h3>Calcul de la Rentabilité</h3>
        <p>Il est essentiel de calculer la rentabilité du minage avant de se lancer. Prenez en compte :</p>
        <ul>
          <li>Coût de l'équipement</li>
          <li>Consommation électrique</li>
          <li>Frais de pool</li>
          <li>Prix de la cryptomonnaie</li>
        </ul>
        <p>Utilisez des calculatrices en ligne pour estimer vos profits potentiels.</p>

        <h3>Sécurité et Maintenance</h3>
        <ul>
          <li>
            <strong>Maintenir l'équipement :</strong> Assurez-vous que vos machines de minage sont bien ventilées et
            propres pour éviter la surchauffe.
          </li>
          <li>
            <strong>Mises à jour logicielles :</strong> Gardez votre logiciel de minage à jour pour bénéficier des
            dernières fonctionnalités et corrections de bugs.
          </li>
          <li>
            <strong>Sécuriser vos récompenses :</strong> Transférez régulièrement vos cryptomonnaies minées vers un
            portefeuille sécurisé.
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Tutorial2
