import React, { Component, useEffect, useRef, useState } from 'react'
import './NavbarIndex.css'
import { SignUp } from '../SignUp/SignUp'
import { toast } from 'sonner'
import { useNavigate } from 'react-router-dom'
import { HiMiniBars3CenterLeft } from 'react-icons/hi2'
import { IoMdClose } from 'react-icons/io'
import coin from '../../assets/coin42px.png'
import coindark from '../../assets/coin42pxdark.png'
import PasswordForgotten from '../Password/PasswordForgotten'
import useLocalStorage from 'use-local-storage'
import { FaMoon } from 'react-icons/fa'
import { IoSunny } from 'react-icons/io5'

const NavbarIndex = () => {
  const [showModal, setShowModal] = useState('')
  const [tokenUser, setTokenUser] = useState('')
  const [showSideBar, setShowSideBar] = useState(false)
  const [isDark, setIsDark] = useLocalStorage('darkmode')
  const sideBarRef = useRef(null)
  const navigate = useNavigate()

  const disconnectUser = () => {
    const user = JSON.parse(localStorage.getItem('user'))

    toast.warning(user.user_display_name + ' déconnecté !')
    localStorage.removeItem('user')
    setTokenUser('')
  }

  const enableSidebar = () => {
    setShowSideBar(true)
  }

  const setLoginPage = () => {
    navigate('/login')
  }

  const setSignUpPage = () => {
    navigate('/register')
  }

  const disableSidebar = () => {
    setShowSideBar(false)
  }

  const handleDashboard = () => {
    const user = JSON.parse(localStorage.getItem('user'))

    if (user.subscription === 'none') {
      toast.error('Vous devez choisir un abonnement pour accéder au Dashboard.')
    } else {
      navigate('/dashboard')
    }
  }

  useEffect(() => {
    if (localStorage.getItem('user') !== null) {
      const user = localStorage.getItem('user')
      setTokenUser(user)
    }
  }, [])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sideBarRef.current && !sideBarRef.current.contains(event.target)) {
        console.log('outside')

        setShowSideBar(false)
      }
    }

    if (showSideBar) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [showSideBar])

  return (
    <>
      <div>
        <div ref={sideBarRef} className={showSideBar ? 'navbar-responsive active' : 'navbar-responsive'}>
          <div className="close-button-navbar">
            <IoMdClose className="close-button" onClick={() => disableSidebar()} />
          </div>

          <ul className="list-mobile">
            <li>
              <a href="#Expertise" onClick={disableSidebar}>
                Expertise
              </a>
            </li>
            <li>
              <a href="#Subscription" onClick={disableSidebar}>
                Tarifs
              </a>
            </li>
            <li>
              <a href="#FAQ" onClick={disableSidebar}>
                FAQ
              </a>
            </li>
            <li>
              <a href="#Contact-us" onClick={disableSidebar}>
                Contactez-Nous
              </a>
            </li>
            {!tokenUser ? (
              <li>
                <a
                  href="#a"
                  className="inscrire-button"
                  onClick={() => {
                    setSignUpPage()
                  }}
                >
                  S'inscrire
                </a>
              </li>
            ) : (
              <li>
                <a onClick={handleDashboard} className="dashboard-button">
                  Dashboard
                </a>
              </li>
            )}
          </ul>
          <div>
            {!tokenUser ? (
              <a onClick={() => setLoginPage()} className="connect-button-mobile">
                Se connecter
              </a>
            ) : (
              <a href="#a" className="disconnect-button-mobile" onClick={disconnectUser}>
                Se déconnecter
              </a>
            )}
          </div>
          <div className="theme-select">
            {isDark ? (
              <FaMoon
                className="dark-mode-mobile"
                onClick={() => {
                  setIsDark(false)
                }}
              />
            ) : (
              <IoSunny
                className="dark-mode-mobile"
                onClick={() => {
                  setIsDark(true)
                }}
              />
            )}
          </div>
        </div>
        <div className="navbar-index">
          <div className="icon-container">
            {isDark ? <img src={coin} alt="icon-coinfinder"></img> : <img src={coindark} alt="icon-coinfinder"></img>}
            <p className="logo-coinfinder">CoinFinder</p>
          </div>

          <ul className="hidden-on-mobile">
            <li>
              <a href="#Expertise">Expertise</a>
            </li>
            <li>
              <a href="#Subscription">Tarifs</a>
            </li>
            <li>
              <a href="#FAQ">FAQ</a>
            </li>
            <li>
              <a href="#Contact-us">Contactez-Nous</a>
            </li>
            {!tokenUser ? (
              <li>
                <a
                  href="#a"
                  className="inscrire-button"
                  onClick={() => {
                    setSignUpPage()
                  }}
                >
                  S'inscrire
                </a>
              </li>
            ) : (
              <li>
                <a onClick={handleDashboard} className="dashboard-button">
                  Dashboard
                </a>
              </li>
            )}
          </ul>

          {!tokenUser ? (
            <a
              className="connect-button hidden-on-mobile"
              onClick={() => {
                setLoginPage()
              }}
            >
              Se connecter
            </a>
          ) : (
            <a href="#a" className="disconnect-button hidden-on-mobile" onClick={disconnectUser}>
              Se déconnecter
            </a>
          )}
          {isDark ? (
            <FaMoon
              className="dark-mode hidden-on-mobile"
              onClick={() => {
                setIsDark(false)
              }}
            />
          ) : (
            <IoSunny
              className="dark-mode hidden-on-mobile"
              onClick={() => {
                setIsDark(true)
              }}
            />
          )}
          <HiMiniBars3CenterLeft
            className="mobile-menu"
            onClick={() => {
              enableSidebar()
            }}
          />
        </div>
        {showModal === 'SignUp' && <SignUp onClose={() => setShowModal('')} onLogIn={() => setShowModal('Login')} />}
        {showModal === 'Password' && <PasswordForgotten onClose={() => setShowModal('')} />}
      </div>
    </>
  )
}

export default NavbarIndex
