import React, { Component, useState } from 'react'
import './ContactForm.css'
import { GoArrowRight } from 'react-icons/go'
import { toast } from 'sonner'

const ContactForm = () => {
  const [result, setResult] = useState('')

  const onSubmit = async (event) => {
    event.preventDefault()
    setResult('Sending....')
    const formData = new FormData(event.target)

    formData.append('access_key', process.env.REACT_APP_CONTACT_FORM_API_KEY)

    const response = await fetch('https://api.web3forms.com/submit', {
      method: 'POST',
      body: formData,
    })

    const data = await response.json()

    if (data.success) {
      toast.success('Email envoyé avec succès ! Nous vous répondrons dès que possible.')
      event.target.reset()
    } else {
      toast.error(data.message)
      setResult(data.message)
    }
  }

  return (
    <div className="contact-form" id="Contact-us">
      <div className="contact-title">
        <h1>Contactez-Nous</h1>
        <p>
          Vous avez une question ou besoin d'aide ? N'hésitez pas à nous contacter. Remplissez le formulaire ci-dessous
          et nous vous répondrons dès que possible.
        </p>
      </div>
      <div className="contact-form-content">
        <form id="contact-form" className="form-contact-email" onSubmit={onSubmit}>
          <div>
            <input type="text" placeholder="Nom*" name="nom" required></input>
            <input type="text" placeholder="Prénom*" name="prenom" required></input>
          </div>
          <div>
            <input type="email" placeholder="Email*" name="email" required></input>
            <input type="text" placeholder="Téléphone" name="telephone"></input>
          </div>
          <div className="text-area-content">
            <textarea className="contact-form-email-content" placeholder="Message*" name="message" required></textarea>
          </div>
          <button type="submit">
            Envoyer <GoArrowRight className="arrow-right-form" />
          </button>
        </form>
      </div>
    </div>
  )
}

export default ContactForm
