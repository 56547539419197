import React, { useEffect, useState } from 'react'
import './Profile.css'
import { useNavigate } from 'react-router-dom'
import { FaUserPen, FaEye, FaEyeSlash } from 'react-icons/fa'
import { IoIosLock } from 'react-icons/io'
import { IoDiamondOutline } from 'react-icons/io5'
import { Toaster, toast } from 'sonner'
import useLocalStorage from 'use-local-storage'
import TopNavbarDashboard from '../../TopNavbarDashboard/TopNavbarDashboard'

const Profile = ({ handleIndexChange }) => {
  const [userData, setUserData] = useLocalStorage('user')
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [passwordVisibleConfirm, setPasswordVisibleConfirm] = useState(false)
  const [oldPasswordVisible, setOldPasswordVisible] = useState(false)
  const [displaySubscription, setDisplaySubscription] = useState('Aucun abonnement')
  const [formData, setFormData] = useState({
    oldPassword: '',
    password: '',
    confirmPassword: '',
  })
  const navigate = useNavigate()

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  const togglePasswordVisibilityConfirm = () => {
    setPasswordVisibleConfirm(!passwordVisibleConfirm)
  }

  const toggleOldPasswordVisibility = () => {
    setOldPasswordVisible(!oldPasswordVisible)
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const resetForm = () => {
    setFormData({
      oldPassword: '',
      password: '',
      confirmPassword: '',
    })
  }

  const handleSubmit = async () => {
    const { oldPassword, password, confirmPassword } = formData

    if (password !== confirmPassword) {
      toast.error('Les nouveaux mots de passe ne correspondent pas.')
      return
    }

    try {
      // Vérifier l'ancien mot de passe
      const loginResponse = await fetch('/api/user/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: userData.user_display_name,
          password: oldPassword,
        }),
      })

      if (loginResponse.ok) {
        const loginData = await loginResponse.json()

        // Ancien mot de passe correct, récupérer toutes les informations de l'utilisateur
        const retrieveResponse = await fetch(`/api/user/retrieve/email/${userData.user_email}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })

        if (retrieveResponse.ok) {
          const userInfo = await retrieveResponse.json()

          // Mettre à jour le mot de passe
          const updateResponse = await fetch(`/api/update-user-password/${userInfo[0].id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              password: password,
            }),
          })

          if (updateResponse.ok) {
            toast.success('Mot de passe mis à jour avec succès')
            resetForm()
          } else {
            toast.error('Échec de la mise à jour du mot de passe.')
          }
        } else {
          toast.error("Échec de la récupération des informations de l'utilisateur.")
        }
      } else {
        toast.error('Ancien mot de passe incorrect.')
      }
    } catch (error) {
      toast.error('Une erreur est survenue. Veuillez réessayer.')
      console.error(error)
    }
  }

  const manageSubscription = () => {
    fetch(`/api/dashboard-user/${userData.customer_stripe_id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        window.location.href = data.url
      })
  }

  useEffect(() => {
    if (userData.subscription === 'none') {
      setDisplaySubscription('Aucun abonnement')
    } else if (userData.subscription === 'subscription-debutant') {
      setDisplaySubscription('Abonnement Débutant')
    } else if (userData.subscription === 'subscription-intermediaire') {
      setDisplaySubscription('Abonnement Intermédiaire')
    } else if (userData.subscription === 'subscription-fullaccess') {
      setDisplaySubscription('Abonnement Full Access')
    }
  })

  return (
    <div className="landing-page-dashboard">
      <Toaster position="top-left" className="toaster-app" expand={false} richColors />
      <TopNavbarDashboard text={'Profile'} handleIndexChange={handleIndexChange} />
      <div className="profile-container">
        <div className="profile-page-content">
          <div className="profile-informations">
            <h2>Informations Personnelles</h2>
            <form className="informations-form">
              <p>Pseudonyme</p>
              <input type="text" value={userData.user_display_name} disabled />
              <p>Email</p>
              <input type="text" value={userData.user_email} disabled />
              <p>Abonnement</p>
              <div className="subscription-container">
                <input className="subscription-input" type="text" value={displaySubscription} disabled />
                <span className="subscription-text" onClick={manageSubscription}>
                  Gérer
                </span>
              </div>
            </form>
          </div>
          <div className="profile-password">
            <h2>Mot de passe</h2>
            <form className="informations-form">
              <p>Ancien Mot de passe</p>
              <div className="password-box">
                <input
                  type={oldPasswordVisible ? 'text' : 'password'}
                  placeholder="******"
                  name="oldPassword"
                  value={formData.oldPassword}
                  onChange={handleChange}
                />
                {oldPasswordVisible ? (
                  <FaEye onClick={toggleOldPasswordVisibility} />
                ) : (
                  <FaEyeSlash onClick={toggleOldPasswordVisibility} />
                )}
              </div>
              <p>Nouveau Mot de passe</p>
              <div className="password-box">
                <input
                  type={passwordVisible ? 'text' : 'password'}
                  placeholder="******"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
                {passwordVisible ? (
                  <FaEye onClick={togglePasswordVisibility} />
                ) : (
                  <FaEyeSlash onClick={togglePasswordVisibility} />
                )}
              </div>
              <p>Confirmer Mot de passe</p>
              <div className="password-box">
                <input
                  type={passwordVisibleConfirm ? 'text' : 'password'}
                  placeholder="******"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                />
                {passwordVisibleConfirm ? (
                  <FaEye onClick={togglePasswordVisibilityConfirm} />
                ) : (
                  <FaEyeSlash onClick={togglePasswordVisibilityConfirm} />
                )}
              </div>
            </form>
          </div>
        </div>
        <div className="save-button-container">
          <button className="save-button" onClick={handleSubmit}>
            Sauvegarder
          </button>
        </div>
      </div>
      <div className="footer-mobile"></div>
    </div>
  )
}

export default Profile
