import React, { useState, useEffect } from 'react'
import './Subscription.css'
import { SlEnvolopeLetter } from 'react-icons/sl'
import { GoArrowRight } from 'react-icons/go'
import { IoCheckmarkSharp } from 'react-icons/io5'
import { toast } from 'sonner'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import 'swiper/css'
import { useNavigate } from 'react-router-dom'

const Subscription = () => {
  const [afficherMois, setAfficherMois] = useState(true)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600)
  const navigate = useNavigate()

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleDefaultSubscription = (productURL) => {
    if (localStorage.getItem('user') === null) {
      toast.error('Vous devez être connnecté pour choisir un abonnement.')
      navigate('/login')
      return
    }

    const userAccount = JSON.parse(localStorage.getItem('user'))

    if (userAccount.subscription !== 'none') {
      toast.error('Vous avez déjà un abonnement actif. Vous pouvez le gérer dans votre espace personnel.')
      return
    }

    const formData = {
      productAPI: productURL,
      userEmail: userAccount.user_email,
    }

    fetch('/api/create-checkout-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then(async (response) => {
        const data = await response.json()
        userAccount.stripe_session_id = data.sessionId
        const subscription = {
          subscription: productURL,
        }
        localStorage.setItem('stripe_subscription', JSON.stringify(subscription))

        localStorage.setItem('user', JSON.stringify(userAccount))
        window.location = data.urlCreated
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const renderCard = (title, price, period, features, productURL, popular = false) => (
    <div className={popular ? 'subscription-card-popular' : 'subscription-card'}>
      <div className={popular ? 'card-subscription-popular' : 'card-subscription'}>
        <div className="card-header">
          <div className="header-text">
            <h1>{title}</h1>
          </div>
        </div>
        <div className="card-price">
          <h1>{price}</h1>
          <p>/{period}</p>
        </div>
        <ul className="card-list">
          <li className="list-title">What's included</li>
          {features.map((feature, index) => (
            <li key={index}>
              <IoCheckmarkSharp className={feature.included ? 'check-logo' : 'uncheck-logo'} />
              <p>{feature.text}</p>
            </li>
          ))}
        </ul>
        <a className="get-started" onClick={() => handleDefaultSubscription(productURL)}>
          S'abonner
          <GoArrowRight className="arrow-right-subscription" />
        </a>
      </div>
    </div>
  )

  const monthlySubscriptions = [
    {
      title: 'Débutant',
      price: '29,99€',
      period: 'mois',
      features: [
        { text: 'All analytics features', included: true },
        { text: 'Up to 5,000,000 tracked visits', included: true },
        { text: 'Dedicated support', included: false },
        { text: 'Up to 50 team members', included: false },
      ],
      productURL: 'price_1P5PSmRudVpbFJ6dInFNiJIC',
      popular: false,
    },
    {
      title: 'Intermédiaire',
      price: '49,99€',
      period: 'mois',
      features: [
        { text: 'All analytics features', included: true },
        { text: 'Up to 5,000,000 tracked visits', included: true },
        { text: 'Dedicated support', included: true },
        { text: 'Up to 50 team members', included: false },
      ],
      productURL: 'price_1P5PUfRudVpbFJ6d9xChtqtN',
      popular: true,
    },
    {
      title: 'Full Access',
      price: '99,99€',
      period: 'mois',
      features: [
        { text: 'All analytics features', included: true },
        { text: 'Up to 5,000,000 tracked visits', included: true },
        { text: 'Dedicated support', included: true },
        { text: 'Up to 50 team members', included: true },
      ],
      productURL: 'price_1P5PVFRudVpbFJ6dIKqjHecM',
      popular: false,
    },
  ]

  const yearlySubscriptions = [
    {
      title: 'Débutant',
      price: '341,99€',
      period: 'année',
      features: [
        { text: 'All analytics features', included: true },
        { text: 'Up to 5,000,000 tracked visits', included: true },
        { text: 'Dedicated support', included: false },
        { text: 'Up to 50 team members', included: false },
      ],
      productURL: 'price_1P8GjARudVpbFJ6dDPgX3Kf8',
      popular: false,
    },
    {
      title: 'Intermédiaire',
      price: '569,99€',
      period: 'année',
      features: [
        { text: 'All analytics features', included: true },
        { text: 'Up to 5,000,000 tracked visits', included: true },
        { text: 'Dedicated support', included: true },
        { text: 'Up to 50 team members', included: false },
      ],
      productURL: 'price_1P8Gl4RudVpbFJ6dCVo5dw56',
      popular: true,
    },
    {
      title: 'Full Access',
      price: '1139,99€',
      period: 'année',
      features: [
        { text: 'All analytics features', included: true },
        { text: 'Up to 5,000,000 tracked visits', included: true },
        { text: 'Dedicated support', included: true },
        { text: 'Up to 50 team members', included: true },
      ],
      productURL: 'price_1P8GmJRudVpbFJ6dMhlX6t6x',
      popular: false,
    },
  ]

  return (
    <>
      <div className="subscription" id="Subscription" data-aos="fade-up">
        <div className="subscription-title">
          <h1>Abonnements</h1>
          <p>
            Explorez notre gamme de forfaits conçus pour convenir à chaque budget et niveau d'expertise. Débloquez des
            fonctionnalités premium et des conseils personnalisés pour élever votre parcours en cryptomonnaie.
          </p>
        </div>
        <div className="button-filter">
          <button onClick={() => setAfficherMois(true)} className={afficherMois ? 'active-button' : ''}>
            Mois
          </button>
          <button
            onClick={() => setAfficherMois(false)}
            className={'button-year' + `${afficherMois ? '' : ' active-button'}`}
          >
            Année
          </button>
        </div>
        {isMobile ? (
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={50}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            initialSlide={1}
            className="cards-subscription"
          >
            {afficherMois
              ? monthlySubscriptions.map((sub, index) => (
                  <SwiperSlide key={index}>
                    {renderCard(sub.title, sub.price, sub.period, sub.features, sub.productURL, sub.popular)}
                  </SwiperSlide>
                ))
              : yearlySubscriptions.map((sub, index) => (
                  <SwiperSlide key={index}>
                    {renderCard(sub.title, sub.price, sub.period, sub.features, sub.productURL, sub.popular)}
                  </SwiperSlide>
                ))}
          </Swiper>
        ) : (
          <div className="cards-subscription">
            {afficherMois
              ? monthlySubscriptions.map((sub, index) =>
                  renderCard(sub.title, sub.price, sub.period, sub.features, sub.productURL, sub.popular)
                )
              : yearlySubscriptions.map((sub, index) =>
                  renderCard(sub.title, sub.price, sub.period, sub.features, sub.productURL, sub.popular)
                )}
          </div>
        )}
      </div>
    </>
  )
}

export default Subscription
