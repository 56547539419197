import React, { Component } from 'react'
import './Footer.css'

export default class Footer extends Component {
  render() {
    return (
      <div className='footer'>
        <div className='list-links'>
            <div className='list-footer'>
                <p>Information</p>
                <ul>
                    <li>Contact Us</li>
                    <li>Delivery</li>
                    <li>Returns</li>
                    <li>FAQ'S</li>
                    <li>Accessibility Statement</li>
                </ul>
            </div>
            <div className='list-footer'>
                <p>Legal & Cookies</p>
                <ul>
                    <li>Contact Us</li>
                    <li>Delivery</li>
                    <li>Returns</li>
                    <li>Returns</li>
                    <li>Returns</li>
                    <li>FAQ'S</li>
                    <li>FAQ'S</li>
                    <li>Accessibility Statement</li>
                </ul>
            </div>
            <div className='list-footer'>
                <p>Our Company</p>
                <ul>
                    <li>Locator</li>
                    <li>Delivery</li>
                    <li>Returns</li>
                    <li>FAQ'S</li>
                    <li>Accessibility Statement</li>
                </ul>
            </div>
            <div className='list-footer'>
                <p>Customer Services</p>
                <ul>
                    <li>Contact Us</li>
                    <li>Delivery</li>
                    <li>Returns</li>
                    <li>FAQ'S</li>
                    <li>Accessibility Statement</li>
                </ul>
            </div>
        </div>
        <div className='social-media'>
            <p>© 2024 CoinFinder All rights reserved</p>
        </div>
        <div className='disclaimer'>
            <p>Toutes les informations présentées sur ce site sont fournies à titre informatif uniquement et ne constituent en aucun cas des conseils en investissement. Nous ne sommes pas des conseillers financiers professionnels et nous vous encourageons vivement à consulter un conseiller financier qualifié avant de prendre toute décision d'investissement. Les performances passées ne garantissent pas les résultats futurs et les investissements comportent des risques, y compris la perte éventuelle de capital. Nous déclinons toute responsabilité pour toute perte ou dommage résultant directement ou indirectement de l'utilisation des informations fournies sur ce site. Vous êtes seul responsable de vos décisions en matière d'investissement.</p>
        </div>
      </div>
    )
  }
}

