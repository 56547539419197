import React, { useEffect, useState } from 'react'
import './Closeout.css'
import TopNavbarDashboard from '../../TopNavbarDashboard/TopNavbarDashboard'
import { GoArrowUpRight } from 'react-icons/go'
import { GoArrowDownLeft } from 'react-icons/go'
import uniswap from '../../../assets/uniswap-uni-logo.png'
import binance from '../../../assets/Binance_Logo.svg.png'
import metamask from '../../../assets/MetaMask_Fox.svg.png'
const Closeout = ({ handleIndexChange }) => {
  const [cryptoData, setCryptoData] = useState(null)
  const [translatedDescription, setTranslatedDescription] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      const id = JSON.parse(localStorage.getItem('crypto'))

      try {
        const response = await fetch(`/api/month-caps-data`)
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données')
        }
        const data = await response.json()

        const foundCrypto = data.find((crypto) => crypto.id === id.symbol)
        if (!foundCrypto) {
          throw new Error(`La crypto-monnaie avec l'ID ${id} n'a pas été trouvée.`)
        }

        setCryptoData(foundCrypto)
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error)
      }
    }

    fetchData()
  }, [])

  const formatNumber = (number) => {
    if (number === null || number === undefined) {
      return ''
    }
    return new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(number)
  }

  const calculatePercentage = (volume, marketCap) => {
    if (volume === null || volume === undefined || marketCap === null || marketCap === undefined || marketCap === 0) {
      return ''
    }
    const percentage = (volume / marketCap) * 100
    return percentage.toFixed(2) + '%'
  }

  if (!cryptoData) {
    return (
      <div className="landing-page-dashboard">
        <TopNavbarDashboard text={'Closeout'} handleIndexChange={handleIndexChange} />
        <div className="landing-page-content"></div>
      </div>
    )
  }

  return (
    <>
      <div className="landing-page-dashboard">
        <TopNavbarDashboard text={'Closeout'} handleIndexChange={handleIndexChange} />
        <div className="landing-page-content">
          <div className="crypto-top">
            <div className="crypto-main">
              <img src={cryptoData.image} alt={cryptoData.name} />
              <h1>
                {cryptoData.name} - {cryptoData.symbol.toUpperCase()}
              </h1>
              <p>{cryptoData.description ? cryptoData.description : 'Pas de description fournie.'}</p>
            </div>
            <div className="crypto-chart">
              <gecko-coin-price-chart-widget
                locale="fr"
                dark-mode="true"
                transparent-background="true"
                coin-id={cryptoData.id}
                initial-currency="eur"
              ></gecko-coin-price-chart-widget>
            </div>
          </div>
          <div className="crypto-bottom">
            <div className="crypto-infos">
              <p>
                Capitalisation Boursière <span>{formatNumber(Math.round(cryptoData.market_cap))}€</span>
              </p>
              <p>
                Volume 24h <span>{formatNumber(Math.round(cryptoData.volume_24h))}</span>
              </p>
              <p>
                Volume/Capitalisation Boursière{' '}
                <span>
                  {cryptoData.marketcap ? calculatePercentage(cryptoData.volume_24h, cryptoData.market_cap) : 0}
                </span>
              </p>
              <p>
                Offre Circulante <span>{formatNumber(Math.round(cryptoData.circulating_supply))}</span>
              </p>
              <p>
                Offre Maximale <span>{formatNumber(Math.round(cryptoData.max_supply))}</span>
              </p>
              <p>
                Évaluation Complètement Diluée{' '}
                <span>{formatNumber(Math.round(cryptoData.fully_diluted_valuation))}€</span>
              </p>
              <p>
                Changement 24h{' '}
                <span className={cryptoData.price_change_percentage_24h > 0 ? 'positive-change' : 'negative-change'}>
                  <div>{cryptoData.price_change_percentage_24h > 0 ? <GoArrowUpRight /> : <GoArrowDownLeft />}</div>
                  {cryptoData.price_change_percentage_24h ? cryptoData.price_change_percentage_24h.toFixed(2) : 0}%
                </span>
              </p>
              <p>
                Changement 1h{' '}
                <span className={cryptoData.price_change_percentage_1h > 0 ? 'positive-change' : 'negative-change'}>
                  <div>{cryptoData.price_change_percentage_1h > 0 ? <GoArrowUpRight /> : <GoArrowDownLeft />}</div>
                  {cryptoData.price_change_percentage_1h ? cryptoData.price_change_percentage_1h.toFixed(2) : 0}%
                </span>
              </p>
              <p>
                Changement 7 jours{' '}
                <span className={cryptoData.price_change_percentage_7d > 0 ? 'positive-change' : 'negative-change'}>
                  <div>{cryptoData.price_change_percentage_7d > 0 ? <GoArrowUpRight /> : <GoArrowDownLeft />}</div>
                  {cryptoData.price_change_percentage_7d ? cryptoData.price_change_percentage_7d.toFixed(2) : 0}%
                </span>
              </p>
            </div>
            <div className="crypto-buy">
              <h2>Où Acheter ?</h2>
              <div className="buy-options">
                <div className="buy-option">
                  <div>
                    <img src={metamask} alt="Metamask" />
                    <h3>Metamask</h3>
                  </div>
                  <p>
                    MetaMask est un portefeuille décentralisé qui vous permet d'acheter et de stocker vos cryptomonnaies
                    en toute sécurité.
                  </p>
                  <a href="https://metamask.io" target="_blank">
                    Aller sur MetaMask <GoArrowUpRight />
                  </a>
                </div>
                <div className="buy-option">
                  <div>
                    <img src={binance} alt="Binance" />
                    <h3>Binance</h3>
                  </div>
                  <p>
                    Binance est une grande bourse de cryptomonnaies offrant des échanges pour une large gamme d'actifs
                    numériques.
                  </p>
                  <a href="https://www.binance.com" target="_blank">
                    Aller sur Binance <GoArrowUpRight />
                  </a>
                </div>
                <div className="buy-option">
                  <div>
                    <img src={uniswap} alt="Uniswap" />
                    <h3>Uniswap</h3>
                  </div>
                  <p>
                    Uniswap est une bourse décentralisée qui vous permet d'échanger des tokens directement depuis votre
                    portefeuille.
                  </p>
                  <a href="https://uniswap.org" target="_blank">
                    Aller sur Uniswap <GoArrowUpRight />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Closeout
