import React from 'react'
import './SubscriptionFailed.css'
import { useNavigate } from 'react-router-dom'

export const SubscriptionFailed = () => {
  const navigate = useNavigate()

  return (
    <div className="error-page">
      <div className="error-container">
        <h1>Paiement Annulé</h1>
        <p
          className="paiement-failed-button"
          onClick={() => {
            navigate('/')
          }}
        >
          Revenir à la page d'accueil
        </p>
      </div>
    </div>
  )
}

export default SubscriptionFailed
