import React, { useEffect, useState } from 'react'
import './MarketCap.css'
import { GoArrowUpRight } from 'react-icons/go'
import { GoArrowDownRight } from 'react-icons/go'

const HighMarketCap = ({ handleIndexChange }) => {
  const [lowCaps, setLowCaps] = useState(null)

  useEffect(() => {
    fetch('/api/high-caps-data', {
      method: 'GET',
    })
      .then(async (response) => {
        const data = await response.json()

        setLowCaps(data)
      })
      .catch((error) => {})
  }, [])

  function formatMarketCap(marketCap) {
    if (marketCap >= 1000000000000) {
      return (marketCap / 1000000000000).toFixed(2) + 'T'
    } else if (marketCap >= 1000000000) {
      return (marketCap / 1000000000).toFixed(2) + 'B'
    } else if (marketCap >= 1000000) {
      return (marketCap / 1000000).toFixed(2) + 'M'
    } else if (marketCap >= 1000) {
      return (marketCap / 1000).toFixed(2) + 'K'
    } else {
      return marketCap.toFixed(2)
    }
  }

  return (
    <div className="market-cap-dashboard">
      <div className="market-cap-container">
        <h1 className="market-cap-title">Coin Spy</h1>
        <div className="market-cap-content">
          <table className="crypto-table">
            <thead>
              <tr>
                <th>Rang</th>
                <th>Nom</th>
                <th>Prix</th>
                <th>Market Cap</th>
                <th>Volume 24H</th>
                <th>7D %</th>
                <th>7D Market</th>
                <th>24H Change</th>
              </tr>
            </thead>
            <tbody>
              {lowCaps !== null
                ? lowCaps.map((data, index) => {
                    return (
                      <tr className="crypto-landing-row" key={index}>
                        <td className="rank-element">{data.cmc_rank}.</td>
                        <td>
                          <div>
                            <img className="icon-crypto-landing" src={data.logo} alt="crypto_icon"></img>{' '}
                            <p>{data.symbol}</p>
                          </div>
                        </td>
                        <td>{data.quote.EUR.price.toFixed(2)}€</td>
                        <td>{formatMarketCap(data.quote.EUR.market_cap)}</td>
                        <td>{formatMarketCap(data.quote.EUR.volume_24h)}</td>
                        <td className={data.quote.EUR.percent_change_7d >= 0 ? 'positif-value' : 'negatif-value'}>
                          {data.quote.EUR.percent_change_7d >= 0 ? (
                            <>
                              <div>
                                <GoArrowUpRight />
                              </div>
                              <span>+{data.quote.EUR.percent_change_7d.toFixed(2)}</span>
                            </>
                          ) : (
                            <>
                              <div>
                                <GoArrowDownRight />
                              </div>
                              <span>{data.quote.EUR.percent_change_7d.toFixed(2)}</span>
                            </>
                          )}
                        </td>
                        <td></td>
                        <td className={data.quote.EUR.percent_change_24h >= 0 ? 'positif-value' : 'negatif-value'}>
                          {data.quote.EUR.percent_change_24h >= 0 ? (
                            <>
                              <div>
                                <GoArrowUpRight />
                              </div>
                              <span>+{data.quote.EUR.percent_change_24h.toFixed(2)}</span>
                            </>
                          ) : (
                            <>
                              <div>
                                <GoArrowDownRight />
                              </div>

                              <span>{data.quote.EUR.percent_change_24h.toFixed(2)}</span>
                            </>
                          )}
                        </td>
                      </tr>
                    )
                  })
                : ''}
            </tbody>
          </table>
        </div>
        <div className="market-cap-description">
          <h1>High Market Cap</h1>
          <p>
            Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression.
            Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme
            assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait
            que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en
            soit modifié. Il a été popularisé dans les années 1960 grâce à la vente de feuilles Letraset contenant des
            passages du Lorem Ipsum, et, plus récemment, par son inclusion dans des applications de mise en page de
            texte, comme Aldus PageMaker.
          </p>
        </div>
      </div>
    </div>
  )
}

export default HighMarketCap
