import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

const Checkout = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      const user = JSON.parse(localStorage.getItem('user'))

      try {
        const customerStripeId = user.customer_stripe_id
        const response = await fetch(`/api/subscription-status/${customerStripeId}`)
        const data = await response.json()

        if (data.subscriptionStatus !== 'active') {
          await fetch(`/api/user/metadata/${user.id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ subscription: 'none' }),
          })

          localStorage.setItem('user', JSON.stringify({ ...user, subscription: 'none' }))
          toast.success('Abonnement annulé avec succès !')
          navigate('/')
        } else {
          navigate('/dashboard')
        }
      } catch (error) {
        console.error('Error checking subscription status:', error)
      }
    }

    checkSubscriptionStatus()
  }, [navigate])

  return <div></div>
}

export default Checkout
