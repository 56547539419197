import React, { useEffect } from 'react'
import './Home.css'
import NavbarIndex from '../navbar-index/NavbarIndex'
import Hero from '../Hero/Hero'
import Slider from '../Slider/Slider'
import 'typeface-roboto'
import Expertise from '../Expertise/Expertise'
import MiddleContent from '../MiddleContent/MiddleContent'
import Subscription from '../Subscription/Subscription'
import Faq from '../FAQ/Faq'
import ContactForm from '../Contact-Form/ContactForm'
import Footer from '../Footer/Footer'
import { Toaster } from 'sonner'
import Advantages from '../Advantages/Advantages'

const Home = () => {
  return (
    <>
      <div className="app">
        <Toaster position="top-left" className="toaster-app" expand={false} richColors />
        <div className="gradient-1"></div>
        <div className="gradient-2"></div>
        <div className="home" id="home">
          <NavbarIndex />
          <Hero />
          <Slider />
          <Advantages />
          <Expertise />
          <MiddleContent />
          <Subscription />
          <Faq />
          <ContactForm />
          <Footer />
        </div>
      </div>
    </>
  )
}

export default Home
