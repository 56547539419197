import React from 'react'
import './Tutorials.css'
import TopNavbarDashboard from '../../TopNavbarDashboard/TopNavbarDashboard'
import { PiPlayCircleThin } from 'react-icons/pi'

const Tutorials = ({ handleIndexChange }) => {
  return (
    <div className="landing-page-dashboard">
      <TopNavbarDashboard text={'Tutoriels'} handleIndexChange={handleIndexChange} />
      <div className="tutorial-content">
        <div className="card-tutorial tutorial-1" onClick={() => handleIndexChange(101)}>
          <div>
            <PiPlayCircleThin />
          </div>
          <h2>Comprendre les Bases des Cryptomonnaies</h2>
          <p>
            Ce tutoriel explique ce que sont les cryptomonnaies, comment elles fonctionnent et pourquoi elles sont
            importantes. Idéal pour les débutants, il fournit une introduction complète aux concepts de base des
            cryptomonnaies.
          </p>
        </div>
        <div className="card-tutorial tutorial-2" onClick={() => handleIndexChange(102)}>
          <div>
            <PiPlayCircleThin />
          </div>
          <h2>Guide Complet du Minage de Cryptomonnaies</h2>
          <p>
            Ce tutoriel couvre les bases du minage de cryptomonnaies, les équipements nécessaires, les logiciels
            utilisés, et les meilleures pratiques pour débuter dans le minage.{' '}
          </p>
        </div>
        <div className="card-tutorial tutorial-3" onClick={() => handleIndexChange(103)}>
          <div>
            <PiPlayCircleThin />
          </div>
          <h2>Les Bases du Trading de Cryptomonnaies</h2>
          <p>
            Ce tutoriel guide les débutants à travers les étapes du trading de cryptomonnaies, y compris les plateformes
            de trading, les types d'ordres, et les stratégies de trading.
          </p>
        </div>
        <div className="card-tutorial tutorial-4" onClick={() => handleIndexChange(104)}>
          <div>
            <PiPlayCircleThin />
          </div>
          <h2>Protéger vos Cryptomonnaies : Guide de Sécurité</h2>
          <p>
            Ce tutoriel couvre les meilleures pratiques pour sécuriser vos cryptomonnaies, y compris l'utilisation de
            portefeuilles sécurisés, l'authentification à deux facteurs et la prévention des escroqueries.
          </p>
        </div>
        <div className="card-tutorial tutorial-5" onClick={() => handleIndexChange(105)}>
          <div>
            <PiPlayCircleThin />
          </div>
          <h2>Comprendre les ICO (Initial Coin Offerings) et les IEO (Initial Exchange Offerings)</h2>
          <p>
            Ce tutoriel explique ce que sont les ICO et les IEO, comment elles fonctionnent, et comment évaluer les
            opportunités d'investissement.
          </p>
        </div>
        <div className="card-tutorial tutorial-6" onClick={() => handleIndexChange(106)}>
          <div>
            <PiPlayCircleThin />
          </div>
          <h2>Explorer la Finance Décentralisée (DeFi) et le Yield Farming</h2>
          <p>
            Ce tutoriel couvre les concepts de la finance décentralisée (DeFi) et du yield farming, comment participer,
            et les risques associés.
          </p>
        </div>
      </div>
      <div className="footer-mobile"></div>
    </div>
  )
}

export default Tutorials
