import React from 'react'
import TopNavbarDashboard from '../../TopNavbarDashboard/TopNavbarDashboard'
import './TutorialPages.css'

const Tutorial4 = ({ handleIndexChange }) => {
  return (
    <div className="landing-page-dashboard">
      <TopNavbarDashboard text={'Tutoriels'} handleIndexChange={handleIndexChange} />
      <div className="landing-page-tutorial">
        <h2>Sécurité des Cryptomonnaies</h2>

        <h3>Introduction à la Sécurité des Cryptomonnaies</h3>
        <p>
          Avec l'augmentation de la valeur et de la popularité des cryptomonnaies, la sécurité est devenue une
          préoccupation majeure. Les utilisateurs doivent prendre des mesures pour protéger leurs actifs contre les
          cybercriminels.
        </p>

        <h3>Types de Portefeuilles</h3>
        <ul>
          <li>
            <strong>Portefeuilles Matériels :</strong> Dispositifs physiques qui stockent vos clés privées hors ligne.
            <ul>
              <li>Exemples : Ledger Nano S, Trezor</li>
            </ul>
          </li>
          <li>
            <strong>Portefeuilles Logiciels :</strong> Applications ou logiciels installés sur votre ordinateur ou
            smartphone.
            <ul>
              <li>Exemples : Electrum, Exodus</li>
            </ul>
          </li>
          <li>
            <strong>Portefeuilles en Ligne :</strong> Stockage des clés privées sur un serveur accessible via internet.
            <ul>
              <li>Exemples : Coinbase, Binance</li>
            </ul>
          </li>
          <li>
            <strong>Portefeuilles en Papier :</strong> Impression de vos clés privées et publiques sur papier.
          </li>
        </ul>

        <h3>Authentification à Deux Facteurs (2FA)</h3>
        <p>
          L'authentification à deux facteurs ajoute une couche supplémentaire de sécurité en demandant une deuxième
          forme d'identification, comme un code envoyé sur votre téléphone, en plus de votre mot de passe.
        </p>

        <h3>Meilleures Pratiques de Sécurité</h3>
        <ul>
          <li>
            Utiliser des Mots de Passe Forts : Combinez lettres, chiffres et symboles. Évitez les mots de passe
            évidents.
          </li>
          <li>Ne pas Réutiliser les Mots de Passe : Utilisez un mot de passe unique pour chaque compte.</li>
          <li>
            Sauvegardes Régulières : Faites des copies de sauvegarde de vos portefeuilles et stockez-les dans des
            endroits sûrs.
          </li>
          <li>
            Mises à Jour Logiciels : Gardez vos logiciels et applications à jour pour bénéficier des dernières
            corrections de sécurité.
          </li>
        </ul>

        <h3>Éviter les Arnaques</h3>
        <ul>
          <li>Phishing : Méfiez-vous des courriels ou sites Web qui demandent vos informations personnelles.</li>
          <li>
            Escroqueries à l'Investissement : Méfiez-vous des offres promettant des rendements élevés sans risque.
          </li>
          <li>
            Ponzi Schemes : Évitez les programmes de type Ponzi qui reposent sur de nouveaux investisseurs pour payer
            les anciens.
          </li>
        </ul>

        <h3>Sécurité des Transactions</h3>
        <ul>
          <li>
            Vérifiez les Adresses : Toujours vérifier deux fois l'adresse du destinataire avant d'envoyer des fonds.
          </li>
          <li>Utilisez des Adresses Uniques : Changez d'adresse régulièrement pour éviter d'être suivi.</li>
          <li>Transactions Anonymes : Utilisez des services de mélange (mixers) pour anonymiser vos transactions.</li>
        </ul>

        <h3>Sécuriser vos Dispositifs</h3>
        <ul>
          <li>Antivirus et Antimalware : Installez et mettez à jour régulièrement vos logiciels antivirus.</li>
          <li>VPN : Utilisez un réseau privé virtuel pour protéger votre connexion internet.</li>
          <li>Sécurité Physique : Protégez vos dispositifs matériels contre le vol et les accès non autorisés.</li>
        </ul>

        <h3>Conclusion</h3>
        <p>
          La sécurité des cryptomonnaies est essentielle pour protéger vos actifs. En suivant ces meilleures pratiques
          et en restant vigilant, vous pouvez minimiser les risques et garantir la sécurité de vos cryptomonnaies. La
          prudence et la préparation sont les clés pour naviguer en toute sécurité dans le monde des cryptomonnaies.
        </p>
      </div>
    </div>
  )
}

export default Tutorial4
