import React from "react";
import { useNavigate } from "react-router-dom";

export const DeniedPage = () => {
  const navigate = useNavigate();

  const nagivateHome = () => {
    navigate("/");
  };
  return (
    <div className="error-page">
      <div className="error-container">
        <h1>Erreur 403</h1>
        <p>Vous n'avez pas accès à cette page.</p>
        <p>Choisissez un abonnement ou connectez-vous.</p>
        <a onClick={nagivateHome}>Revenir à l'accueil</a>
      </div>
    </div>
  );
};

export default DeniedPage;
