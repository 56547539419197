import { React, useState, useEffect } from 'react'
import './PasswordForgotten.css'
import validator from 'validator'
import { toast } from 'sonner'
import CryptoJS from 'crypto-js'
import { Toaster } from 'sonner'
import background from '../../assets/login-background.png'
import coin from '../../assets/coin42px.png'
import { FaEye } from 'react-icons/fa'
import { FaEyeSlash } from 'react-icons/fa'
import { Navigate, useNavigate } from 'react-router-dom'

export const PasswordForgotten = ({ onClose }) => {
  const [formData, setFormData] = useState({
    email: '',
  })
  const navigate = useNavigate()

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const displayHome = () => {
    navigate('/')
  }

  function generateRandomBytes(length) {
    const randomBytes = CryptoJS.lib.WordArray.random(length)
    return randomBytes.toString(CryptoJS.enc.Hex)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!validator.isEmail(formData.email)) {
      toast.error("Format d'email incorrect")
      return
    }

    const token = generateRandomBytes(64)

    fetch(`/api/user/retrieve/email/${formData.email}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(async (response) => {
        const data = await response.json()
        let userId = null

        try {
          userId = data[0].id
        } catch (err) {
          toast.error('Aucun compte relié à cette adresse email.')
          return
        }
        const userData = {
          password_token: token,
        }

        fetch(`/api/user/metadata/${userId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userData),
        })
          .then(async (response) => {
            const data = await response.json()

            const emailData = {
              to: formData.email,
              subject: 'CoinFinder - Mot de passe oublié',
              text: `Bonjour,\n\nVous avez demandé à réinitialiser votre mot de passe sur CoinFinder.\n\nPour réinitialiser votre mot de passe, veuillez cliquer sur le lien suivant : https://coinfinder.fr/reset-password?token=${userData.password_token}\n\nSi vous n'êtes pas à l'origine de cette demande, veuillez ignorer cet email.\n\nCordialement,\nL'équipe CoinFinder`,
            }

            fetch('/api/send-email', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(emailData),
            })

            toast.success(
              'Si un compte est relié à cette adresse email, vous recevrez un mail pour réinitialiser votre mot de passe.'
            )

            setTimeout(() => {
              navigate('/')
            }, 1500)
          })

          .catch((error) => {
            console.log(error)
          })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <Toaster position="top-left" className="toaster-app" expand={false} richColors />
      <div className="login">
        <div className="content-img-mobile">
          <img className="background-image" src={background} alt="login-image" />
          <div onClick={displayHome}>
            <img src={coin} alt="coin-logo"></img>
            <a className="logo-login">CoinFinder</a>
          </div>
        </div>
        <div className="content-login">
          <div className="login-title">
            <h1>Réinitialiser Mot de Passe</h1>
            <p>Entrez votre adresse e-mail !</p>
          </div>
          <div className="login-form">
            <form id="login-form" onSubmit={handleSubmit}>
              <p>
                Email<span>*</span>
              </p>
              <input type="email" name="email" placeholder="johndoe@email.com" onChange={handleChange} />
              <button type="submit">Envoyer e-mail</button>
            </form>
          </div>
        </div>
        <div className="content-img">
          <img className="background-image" src={background} alt="login-image" />
          <div onClick={displayHome}>
            <img src={coin} alt="coin-logo"></img>
            <a className="logo-login">
              <br></br>CoinFinder
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default PasswordForgotten
