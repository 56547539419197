import React from 'react'
import TopNavbarDashboard from '../../TopNavbarDashboard/TopNavbarDashboard'
import './TutorialPages.css'

const Tutorial6 = ({ handleIndexChange }) => {
  return (
    <div className="landing-page-dashboard">
      <TopNavbarDashboard text={'Tutoriels'} handleIndexChange={handleIndexChange} />
      <div className="landing-page-tutorial">
        <h2>DeFi et Yield Farming</h2>

        <h3>Introduction à la DeFi</h3>
        <p>
          La finance décentralisée (DeFi) utilise des contrats intelligents sur des blockchains pour offrir des services
          financiers sans intermédiaires traditionnels comme les banques.
        </p>

        <h3>Concepts Clés de la DeFi</h3>
        <ul>
          <li>
            <strong>Contrats Intelligents :</strong> Programmes auto-exécutants sur une blockchain qui exécutent des
            transactions automatiquement lorsque les conditions sont remplies.
          </li>
          <li>
            <strong>DApps :</strong> Applications décentralisées qui fonctionnent sur des réseaux blockchain.
          </li>
          <li>
            <strong>Tokens ERC-20 :</strong> Standards de tokens utilisés sur la blockchain Ethereum pour créer et
            échanger des actifs numériques.
          </li>
        </ul>

        <h3>Services DeFi</h3>
        <ul>
          <li>
            <strong>Prêt et Emprunt :</strong> Plateformes comme Aave et Compound permettent aux utilisateurs de prêter
            et d'emprunter des cryptomonnaies.
          </li>
          <li>
            <strong>Échanges Décentralisés (DEX) :</strong> Uniswap et SushiSwap permettent l'échange de tokens sans
            intermédiaires.
          </li>
          <li>
            <strong>Assurance Décentralisée :</strong> Nexus Mutual offre des couvertures d'assurance pour les
            utilisateurs de DeFi.
          </li>
        </ul>

        <h3>Introduction au Yield Farming</h3>
        <p>
          Le yield farming est une méthode pour générer des rendements en utilisant vos cryptomonnaies dans les
          protocoles DeFi. Les utilisateurs fournissent des liquidités à des pools et reçoivent des récompenses en
          retour.
        </p>

        <h3>Participer au Yield Farming</h3>
        <ol>
          <li>
            <strong>Choisir une Plateforme :</strong> Recherchez des plateformes réputées comme Yearn.Finance, Curve
            Finance, ou PancakeSwap.
          </li>
          <li>
            <strong>Fournir des Liquidités :</strong> Déposez des tokens dans un pool de liquidités.
          </li>
          <li>
            <strong>Récolter les Récompenses :</strong> Gagnez des tokens de récompense en proportion de votre
            contribution au pool.
          </li>
        </ol>

        <h3>Risques du Yield Farming</h3>
        <ul>
          <li>
            <strong>Risque de Perte Impermanente :</strong> La valeur des tokens déposés peut fluctuer, entraînant des
            pertes potentielles.
          </li>
          <li>
            <strong>Risque de Contrat Intelligent :</strong> Les bugs ou failles dans les contrats intelligents peuvent
            entraîner des pertes de fonds.
          </li>
          <li>
            <strong>Risque de Liquidité :</strong> Les pools peuvent devenir illiquides, rendant difficile le retrait
            des fonds.
          </li>
        </ul>

        <h3>Maximiser les Gains</h3>
        <ul>
          <li>
            <strong>Diversification :</strong> Répartissez vos investissements sur plusieurs plateformes et pools.
          </li>
          <li>
            <strong>Surveillance Régulière :</strong> Suivez les performances et ajustez vos positions en fonction des
            conditions du marché.
          </li>
          <li>
            <strong>Réinvestissement des Récompenses :</strong> Réinvestissez vos récompenses pour profiter de l'effet
            composé.
          </li>
        </ul>

        <h3>Conclusion</h3>
        <p>
          La DeFi et le yield farming offrent des opportunités pour générer des rendements élevés, mais ils comportent
          également des risques. Une compréhension approfondie des protocoles et des meilleures pratiques est
          essentielle pour maximiser les gains tout en minimisant les risques. La vigilance et la gestion proactive des
          investissements sont cruciales dans ce domaine en évolution rapide.
        </p>
      </div>
    </div>
  )
}

export default Tutorial6
