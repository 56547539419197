import React from 'react'
import './EBook.css'
import wallpaper from '../../../assets/reading-wallpaper.jpg'
import TopNavbarDashboard from '../../TopNavbarDashboard/TopNavbarDashboard'
import { FaDownload } from 'react-icons/fa'
import { IoEyeSharp } from 'react-icons/io5'

import ebookPDF from '../../../assets/EBOOK_CoinFinder.pdf'

const EBook = ({ handleIndexChange }) => {
  const downloadFile = () => {
    const link = document.createElement('a')
    link.href = ebookPDF
    link.download = 'EBOOK_CoinFinder.pdf'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const viewFile = () => {
    window.open(ebookPDF, '_blank')
  }

  return (
    <div className="landing-page-dashboard">
      <TopNavbarDashboard text={'E-Book'} handleIndexChange={handleIndexChange} />
      <div className="e-book-page">
        <div className="e-book-content">
          <div className="e-book-img">
            <img src={wallpaper} alt="ebook_image" />
          </div>
          <h2>Download our E-Book & learn more about the crypto.</h2>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
            industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged.{' '}
          </p>
          <div className="e-book-button">
            <div onClick={downloadFile}>
              <FaDownload />
              <a>Télécharger</a>
            </div>
            <div className="view-ebook" onClick={viewFile}>
              <IoEyeSharp />
              <a>Voir</a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-mobile"></div>
    </div>
  )
}

export default EBook
