import React, { useEffect, useState } from 'react'
import './ResetPassword.css'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Toaster, toast } from 'sonner'
import background from '../../assets/login-background.png'
import coin from '../../assets/coin42px.png'
import { FaEye } from 'react-icons/fa'
import { FaEyeSlash } from 'react-icons/fa'

export const ResetPassword = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false)
  const [userId, setUserId] = useState(null)
  const [formData, setFormData] = useState({
    password: '',
    passwordConfirm: '',
  })

  const displayHome = () => {
    navigate('/')
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  const togglePasswordVisibilityConfirm = () => {
    setPasswordConfirmVisible(!passwordConfirmVisible)
  }

  const token = searchParams.get('token')

  const handleSubmit = (e) => {
    e.preventDefault()

    if (userId != null) {
      if (formData.password == '') {
        toast.error('Mot de passe invalide.')
      } else if (formData.password !== formData.passwordConfirm) {
        toast.error('Les mots de passe ne correspondent pas.')
      } else {
        fetch(`/api/update-user-password/${userId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            password: formData.password,
          }),
        })
          .then(async (response) => {
            const data = await response.json()

            const passwordToken = {
              password_token: '',
            }

            fetch(`/api/user/metadata/${userId}`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(passwordToken),
            })
              .then(async (response) => {
                const data = await response.json()

                toast.success('Mot de passe mit à jour.')

                setTimeout(() => {
                  navigate('/')
                }, 1000)
              })
              .catch((error) => {
                console.log(error)
              })
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  useEffect(() => {
    fetch(`/api/user/metadata/user-by-password-token/${token}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(async (response) => {
        const data = await response.json()

        setUserId(data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  return (
    <>
      <Toaster position="top-left" className="toaster-app" expand={false} richColors />
      <div className="login">
        <div className="content-img-mobile">
          <img className="background-image" src={background} alt="login-image" />
          <div onClick={displayHome}>
            <img src={coin} alt="coin-logo"></img>
            <a className="logo-login">CoinFinder</a>
          </div>
        </div>
        <div className="content-login">
          <div className="login-title">
            <h1>Réinitialiser Mot de Passe</h1>
            <p>Entrez votre nouveau mot de passe!</p>
          </div>
          <div className="login-form">
            <form id="login-form" onSubmit={handleSubmit}>
              <p>
                Nouveau Mot de Passe<span>*</span>
              </p>
              <div className="password-box">
                <input
                  type={passwordVisible ? 'text' : 'password'}
                  placeholder="******"
                  name="password"
                  onChange={handleChange}
                />
                {passwordVisible ? (
                  <FaEye onClick={togglePasswordVisibility} />
                ) : (
                  <FaEyeSlash onClick={togglePasswordVisibility} />
                )}
              </div>
              <p>
                Confirmer Mot de Passe<span>*</span>
              </p>
              <div className="password-box">
                <input
                  type={passwordConfirmVisible ? 'text' : 'password'}
                  placeholder="******"
                  name="passwordConfirm"
                  onChange={handleChange}
                />
                {passwordConfirmVisible ? (
                  <FaEye onClick={togglePasswordVisibilityConfirm} />
                ) : (
                  <FaEyeSlash onClick={togglePasswordVisibilityConfirm} />
                )}
              </div>
              <button type="submit">Réinitialiser</button>
            </form>
          </div>
        </div>
        <div className="content-img">
          <img className="background-image" src={background} alt="login-image" />
          <div onClick={displayHome}>
            <img src={coin} alt="coin-logo"></img>
            <a className="logo-login">
              <br></br>CoinFinder
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResetPassword
