import React, { useState } from 'react'
import './SidebarDashboard.css'
import coin from '../../assets/coin.png'
import coindark from '../../assets/coindark.png'
import useLocalStorage from 'use-local-storage'
import { useNavigate } from 'react-router-dom'
import { HiMiniBars3CenterLeft } from 'react-icons/hi2'
import { FaMoon } from 'react-icons/fa'
import { IoSunny } from 'react-icons/io5'
import { FaCircleUser } from 'react-icons/fa6'
import { IoHomeSharp } from 'react-icons/io5'
import { IoMdClose } from 'react-icons/io'

export const SidebarDashboard = ({ onIndexChange }) => {
  const [isDark, setIsDark] = useLocalStorage('darkmode')
  const [activeIndex, setActiveIndex] = useState(0)
  const [showSideBar, setShowSideBar] = useState(false)
  const navigate = useNavigate()

  const enableSidebar = () => {
    setShowSideBar(true)
  }

  const disableSidebar = () => {
    setShowSideBar(false)
  }

  const displayHome = () => {
    navigate('/')
  }

  const setIsDarkMode = () => {
    setIsDark(!isDark)
  }

  const handleMenuClick = (index) => {
    setActiveIndex(index)
    // Call onIndexChange if you need to notify parent component
    if (onIndexChange) onIndexChange(index)
  }

  return (
    <>
      <div className="sidebar-dashboard">
        <div className="sidebar-top-content">
          <div className="sidebar-logo" onClick={displayHome}>
            {isDark ? <img src={coin} alt="icon-coinfinder"></img> : <img src={coindark} alt="icon-coinfinder"></img>}
            <p className="logo-coinfinder">CoinFinder</p>
          </div>
          <div className="sidebar-buttons">
            <p>Menu</p>
            <ul>
              <li className={activeIndex === 0 ? 'active' : ''} onClick={() => handleMenuClick(0)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="4" y="4" width="7" height="7" rx="1.5" fill="currentColor" fillOpacity="currentOpacity" />
                  <path
                    opacity="0.4"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13 5.5C13 4.67157 13.6716 4 14.5 4H18.5C19.3284 4 20 4.67157 20 5.5V9.5C20 10.3284 19.3284 11 18.5 11H14.5C13.6716 11 13 10.3284 13 9.5V5.5ZM4 14.5C4 13.6716 4.67157 13 5.5 13H9.5C10.3284 13 11 13.6716 11 14.5V18.5C11 19.3284 10.3284 20 9.5 20H5.5C4.67157 20 4 19.3284 4 18.5V14.5ZM14.5 13C13.6716 13 13 13.6716 13 14.5V18.5C13 19.3284 13.6716 20 14.5 20H18.5C19.3284 20 20 19.3284 20 18.5V14.5C20 13.6716 19.3284 13 18.5 13H14.5Z"
                    fill="var(--primary-font-color)"
                  />
                </svg>
                Dashboard
              </li>
              <li className={activeIndex === 2 ? 'active' : ''} onClick={() => handleMenuClick(2)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.9906 11C19.7156 11 19.4906 11.225 19.4906 11.5V12.293L19.4808 12.291C19.1418 12.334 17.2425 12.6905 17.2425 14.9805C17.2425 18.3215 20.7015 17.3335 20.7015 19.5625C20.7015 20.6785 19.9813 20.7051 19.8343 20.7051C19.7193 20.7051 19.0545 20.7573 18.8675 19.6973C18.8015 19.3253 18.4545 19.0684 18.0765 19.0684H17.8148C17.3198 19.0684 16.919 19.514 17.014 20C17.408 22.014 19.0652 22.1974 19.3402 22.2344L19.4711 22.2051V23.0332C19.4711 23.3082 19.6961 23.5332 19.9711 23.5332C20.2461 23.5332 20.4711 23.3082 20.4711 23.0332V22.2129C20.9981 22.1209 22.5941 21.6858 22.5941 19.5488C22.5941 16.3428 19.1342 16.9543 19.1332 14.9883C19.1332 13.8613 19.755 13.8262 19.889 13.8262C20.085 13.8262 20.4957 13.9647 20.6527 14.7227C20.7347 15.1167 21.0515 15.416 21.4535 15.416H21.7132C22.2372 15.416 22.6163 14.9222 22.5023 14.4102C22.1173 12.6812 20.7686 12.3714 20.4906 12.3184V11.5C20.4906 11.225 20.2656 11 19.9906 11Z"
                    fill="currentColor"
                    fill-opacity="currentOpacity"
                  />
                  <path
                    opacity="0.4"
                    d="M5 3C3.895 3 3 3.895 3 5V19C3 20.105 3.895 21 5 21H14C14.552 21 15 20.552 15 20V15C15 13.523 15.809 12.2477 17 11.5547V11C17 9.895 17.895 9 19 9H20C20.552 9 21 8.552 21 8V5C21 3.895 20.105 3 19 3H5ZM10 6H14C14.552 6 15 6.448 15 7C15 7.552 14.552 8 14 8H10C9.448 8 9 7.552 9 7C9 6.448 9.448 6 10 6Z"
                    fill="var(--primary-font-color)"
                    fill-opacity="0.4"
                  />
                </svg>
                Coin Spy
              </li>
              <li className={activeIndex === 6 ? 'active' : ''} onClick={() => handleMenuClick(6)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    opacity="0.4"
                    d="M12.331 2.00636C12.0466 1.99475 11.7589 1.99926 11.4677 2.01807C7.45257 2.2787 4.36597 5.61067 4.03409 9.56886L4.02042 9.55128L3.17862 19.8384C3.08362 21.0034 4.00376 22.0005 5.17276 22.0005H14.0009C14.5529 22.0005 15.0009 21.5525 15.0009 21.0005C15.0009 20.4485 14.5529 20.0005 14.0009 20.0005H12.0009C8.68689 20.0005 6.00089 17.3145 6.00089 14.0005V13.0005C6.00089 11.8955 6.89589 11.0005 8.00089 11.0005H16.0009C17.1059 11.0005 18.0009 11.8955 18.0009 13.0005V16.0005H13.913C13.8097 15.7084 13.6185 15.4554 13.3657 15.2764C13.1128 15.0973 12.8107 15.0009 12.5009 15.0005C12.1031 15.0005 11.7215 15.1585 11.4402 15.4398C11.1589 15.7211 11.0009 16.1027 11.0009 16.5005C11.0014 16.8794 11.1453 17.244 11.4036 17.5212C11.6619 17.7984 12.0156 17.9675 12.3935 17.9946C12.4291 17.9985 12.465 18.0005 12.5009 18.0005H19.0009H20.0009C21.1009 18.0005 22.0009 17.1005 22.0009 16.0005V14.0005C22.0009 12.9005 21.1009 12.0005 20.0009 12.0005V10.0005C20.0009 5.69268 16.5962 2.18045 12.331 2.00636Z"
                    fill="var(--primary-font-color)"
                    fill-opacity="0.4"
                  />
                  <path
                    d="M18 16V13C18 13 18.9 12 20 12C21.1 12 22 12.9 22 14V16C22 17.1 21.1 18 20 18H19H12.5C12.4641 18 12.4283 17.998 12.3926 17.9941C12.0147 17.967 11.661 17.7979 11.4027 17.5207C11.1444 17.2435 11.0005 16.8789 11 16.5C11 16.1022 11.158 15.7206 11.4393 15.4393C11.7206 15.158 12.1022 15 12.5 15C12.8098 15.0004 13.1119 15.0968 13.3648 15.2759C13.6176 15.4549 13.8088 15.7079 13.9121 16H18Z"
                    fill="currentColor"
                    fill-opacity="currentOpacity"
                  />
                </svg>
                Ressources
              </li>
              <li className={activeIndex === 4 ? 'active' : ''} onClick={() => handleMenuClick(4)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    opacity="0.4"
                    d="M17 2H7C5.34315 2 4 3.34315 4 5V19H20V5C20 3.34315 18.6569 2 17 2Z"
                    fill="var(--primary-font-color)"
                    fill-opacity="0.4"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.25 7C7.25 6.58579 7.58579 6.25 8 6.25H16C16.4142 6.25 16.75 6.58579 16.75 7C16.75 7.41421 16.4142 7.75 16 7.75H8C7.58579 7.75 7.25 7.41421 7.25 7Z"
                    fill="currentColor"
                    fill-opacity="currentOpacity"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.25 11C7.25 10.5858 7.58579 10.25 8 10.25H12C12.4142 10.25 12.75 10.5858 12.75 11C12.75 11.4142 12.4142 11.75 12 11.75H8C7.58579 11.75 7.25 11.4142 7.25 11Z"
                    fill="currentColor"
                    fill-opacity="currentOpacity"
                  />
                  <path
                    d="M20 16H7C5.34315 16 4 17.3431 4 19C4 20.6569 5.34315 22 7 22H17C18.6569 22 20 20.6569 20 19V16Z"
                    fill="currentColor"
                    fill-opacity="currentOpacity"
                  />
                </svg>
                E-Book
              </li>
              <li className={activeIndex === 5 ? 'active' : ''} onClick={() => handleMenuClick(5)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.9906 11C19.7156 11 19.4906 11.225 19.4906 11.5V12.293L19.4808 12.291C19.1418 12.334 17.2425 12.6905 17.2425 14.9805C17.2425 18.3215 20.7015 17.3335 20.7015 19.5625C20.7015 20.6785 19.9813 20.7051 19.8343 20.7051C19.7193 20.7051 19.0545 20.7573 18.8675 19.6973C18.8015 19.3253 18.4545 19.0684 18.0765 19.0684H17.8148C17.3198 19.0684 16.919 19.514 17.014 20C17.408 22.014 19.0652 22.1974 19.3402 22.2344L19.4711 22.2051V23.0332C19.4711 23.3082 19.6961 23.5332 19.9711 23.5332C20.2461 23.5332 20.4711 23.3082 20.4711 23.0332V22.2129C20.9981 22.1209 22.5941 21.6858 22.5941 19.5488C22.5941 16.3428 19.1342 16.9543 19.1332 14.9883C19.1332 13.8613 19.755 13.8262 19.889 13.8262C20.085 13.8262 20.4957 13.9647 20.6527 14.7227C20.7347 15.1167 21.0515 15.416 21.4535 15.416H21.7132C22.2372 15.416 22.6163 14.9222 22.5023 14.4102C22.1173 12.6812 20.7686 12.3714 20.4906 12.3184V11.5C20.4906 11.225 20.2656 11 19.9906 11Z"
                    fill="currentColor"
                    fill-opacity="currentOpacity"
                  />
                  <path
                    opacity="0.4"
                    d="M5 3C3.895 3 3 3.895 3 5V19C3 20.105 3.895 21 5 21H14C14.552 21 15 20.552 15 20V15C15 13.523 15.809 12.2477 17 11.5547V11C17 9.895 17.895 9 19 9H20C20.552 9 21 8.552 21 8V5C21 3.895 20.105 3 19 3H5ZM10 6H14C14.552 6 15 6.448 15 7C15 7.552 14.552 8 14 8H10C9.448 8 9 7.552 9 7C9 6.448 9.448 6 10 6Z"
                    fill="var(--primary-font-color)"
                    fill-opacity="0.4"
                  />
                </svg>
                Tutorials
              </li>
            </ul>
          </div>
        </div>
        <div className="sidebar-bottom-content"></div>
      </div>
      <div className="sidebar-dashboard-mobile">
        <div className="icon-container-mobile" onClick={displayHome}>
          {isDark ? <img src={coin} alt="icon-coinfinder"></img> : <img src={coindark} alt="icon-coinfinder"></img>}
          <p className="logo-coinfinder">CoinFinder</p>
        </div>
        <div className="select-sidebar-mobile">
          <HiMiniBars3CenterLeft onClick={enableSidebar} />
        </div>
      </div>
      <div className={showSideBar ? 'sidebar-dashboard-mobile-content active' : 'sidebar-dashboard-mobile-content'}>
        <div className="sidebar-buttons-mobile">
          <div className="close-button-navbar">
            <IoMdClose className="close-button" onClick={disableSidebar} />
          </div>
          <ul>
            <li
              className={activeIndex === 0 ? 'active' : ''}
              onClick={() => {
                handleMenuClick(0)
                disableSidebar()
              }}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="4" y="4" width="7" height="7" rx="1.5" fill="currentColor" fillOpacity="currentOpacity" />
                <path
                  opacity="0.4"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13 5.5C13 4.67157 13.6716 4 14.5 4H18.5C19.3284 4 20 4.67157 20 5.5V9.5C20 10.3284 19.3284 11 18.5 11H14.5C13.6716 11 13 10.3284 13 9.5V5.5ZM4 14.5C4 13.6716 4.67157 13 5.5 13H9.5C10.3284 13 11 13.6716 11 14.5V18.5C11 19.3284 10.3284 20 9.5 20H5.5C4.67157 20 4 19.3284 4 18.5V14.5ZM14.5 13C13.6716 13 13 13.6716 13 14.5V18.5C13 19.3284 13.6716 20 14.5 20H18.5C19.3284 20 20 19.3284 20 18.5V14.5C20 13.6716 19.3284 13 18.5 13H14.5Z"
                  fill="var(--primary-font-color)"
                />
              </svg>
              Dashboard
            </li>
            <li
              className={activeIndex === 2 ? 'active' : ''}
              onClick={() => {
                handleMenuClick(2)
                disableSidebar()
              }}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.9906 11C19.7156 11 19.4906 11.225 19.4906 11.5V12.293L19.4808 12.291C19.1418 12.334 17.2425 12.6905 17.2425 14.9805C17.2425 18.3215 20.7015 17.3335 20.7015 19.5625C20.7015 20.6785 19.9813 20.7051 19.8343 20.7051C19.7193 20.7051 19.0545 20.7573 18.8675 19.6973C18.8015 19.3253 18.4545 19.0684 18.0765 19.0684H17.8148C17.3198 19.0684 16.919 19.514 17.014 20C17.408 22.014 19.0652 22.1974 19.3402 22.2344L19.4711 22.2051V23.0332C19.4711 23.3082 19.6961 23.5332 19.9711 23.5332C20.2461 23.5332 20.4711 23.3082 20.4711 23.0332V22.2129C20.9981 22.1209 22.5941 21.6858 22.5941 19.5488C22.5941 16.3428 19.1342 16.9543 19.1332 14.9883C19.1332 13.8613 19.755 13.8262 19.889 13.8262C20.085 13.8262 20.4957 13.9647 20.6527 14.7227C20.7347 15.1167 21.0515 15.416 21.4535 15.416H21.7132C22.2372 15.416 22.6163 14.9222 22.5023 14.4102C22.1173 12.6812 20.7686 12.3714 20.4906 12.3184V11.5C20.4906 11.225 20.2656 11 19.9906 11Z"
                  fill="currentColor"
                  fill-opacity="currentOpacity"
                />
                <path
                  opacity="0.4"
                  d="M5 3C3.895 3 3 3.895 3 5V19C3 20.105 3.895 21 5 21H14C14.552 21 15 20.552 15 20V15C15 13.523 15.809 12.2477 17 11.5547V11C17 9.895 17.895 9 19 9H20C20.552 9 21 8.552 21 8V5C21 3.895 20.105 3 19 3H5ZM10 6H14C14.552 6 15 6.448 15 7C15 7.552 14.552 8 14 8H10C9.448 8 9 7.552 9 7C9 6.448 9.448 6 10 6Z"
                  fill="var(--primary-font-color)"
                  fill-opacity="0.4"
                />
              </svg>
              Coin Spy
            </li>
            <li
              className={activeIndex === 6 ? 'active' : ''}
              onClick={() => {
                handleMenuClick(6)
                disableSidebar()
              }}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  opacity="0.4"
                  d="M12.331 2.00636C12.0466 1.99475 11.7589 1.99926 11.4677 2.01807C7.45257 2.2787 4.36597 5.61067 4.03409 9.56886L4.02042 9.55128L3.17862 19.8384C3.08362 21.0034 4.00376 22.0005 5.17276 22.0005H14.0009C14.5529 22.0005 15.0009 21.5525 15.0009 21.0005C15.0009 20.4485 14.5529 20.0005 14.0009 20.0005H12.0009C8.68689 20.0005 6.00089 17.3145 6.00089 14.0005V13.0005C6.00089 11.8955 6.89589 11.0005 8.00089 11.0005H16.0009C17.1059 11.0005 18.0009 11.8955 18.0009 13.0005V16.0005H13.913C13.8097 15.7084 13.6185 15.4554 13.3657 15.2764C13.1128 15.0973 12.8107 15.0009 12.5009 15.0005C12.1031 15.0005 11.7215 15.1585 11.4402 15.4398C11.1589 15.7211 11.0009 16.1027 11.0009 16.5005C11.0014 16.8794 11.1453 17.244 11.4036 17.5212C11.6619 17.7984 12.0156 17.9675 12.3935 17.9946C12.4291 17.9985 12.465 18.0005 12.5009 18.0005H19.0009H20.0009C21.1009 18.0005 22.0009 17.1005 22.0009 16.0005V14.0005C22.0009 12.9005 21.1009 12.0005 20.0009 12.0005V10.0005C20.0009 5.69268 16.5962 2.18045 12.331 2.00636Z"
                  fill="var(--primary-font-color)"
                  fill-opacity="0.4"
                />
                <path
                  d="M18 16V13C18 13 18.9 12 20 12C21.1 12 22 12.9 22 14V16C22 17.1 21.1 18 20 18H19H12.5C12.4641 18 12.4283 17.998 12.3926 17.9941C12.0147 17.967 11.661 17.7979 11.4027 17.5207C11.1444 17.2435 11.0005 16.8789 11 16.5C11 16.1022 11.158 15.7206 11.4393 15.4393C11.7206 15.158 12.1022 15 12.5 15C12.8098 15.0004 13.1119 15.0968 13.3648 15.2759C13.6176 15.4549 13.8088 15.7079 13.9121 16H18Z"
                  fill="currentColor"
                  fill-opacity="currentOpacity"
                />
              </svg>
              Ressources
            </li>
            <li
              className={activeIndex === 4 ? 'active' : ''}
              onClick={() => {
                handleMenuClick(4)
                disableSidebar()
              }}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  opacity="0.4"
                  d="M17 2H7C5.34315 2 4 3.34315 4 5V19H20V5C20 3.34315 18.6569 2 17 2Z"
                  fill="var(--primary-font-color)"
                  fill-opacity="0.4"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.25 7C7.25 6.58579 7.58579 6.25 8 6.25H16C16.4142 6.25 16.75 6.58579 16.75 7C16.75 7.41421 16.4142 7.75 16 7.75H8C7.58579 7.75 7.25 7.41421 7.25 7Z"
                  fill="currentColor"
                  fill-opacity="currentOpacity"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.25 11C7.25 10.5858 7.58579 10.25 8 10.25H12C12.4142 10.25 12.75 10.5858 12.75 11C12.75 11.4142 12.4142 11.75 12 11.75H8C7.58579 11.75 7.25 11.4142 7.25 11Z"
                  fill="currentColor"
                  fill-opacity="currentOpacity"
                />
                <path
                  d="M20 16H7C5.34315 16 4 17.3431 4 19C4 20.6569 5.34315 22 7 22H17C18.6569 22 20 20.6569 20 19V16Z"
                  fill="currentColor"
                  fill-opacity="currentOpacity"
                />
              </svg>
              E-Book
            </li>
            <li
              className={activeIndex === 5 ? 'active' : ''}
              onClick={() => {
                handleMenuClick(5)
                disableSidebar()
              }}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.9906 11C19.7156 11 19.4906 11.225 19.4906 11.5V12.293L19.4808 12.291C19.1418 12.334 17.2425 12.6905 17.2425 14.9805C17.2425 18.3215 20.7015 17.3335 20.7015 19.5625C20.7015 20.6785 19.9813 20.7051 19.8343 20.7051C19.7193 20.7051 19.0545 20.7573 18.8675 19.6973C18.8015 19.3253 18.4545 19.0684 18.0765 19.0684H17.8148C17.3198 19.0684 16.919 19.514 17.014 20C17.408 22.014 19.0652 22.1974 19.3402 22.2344L19.4711 22.2051V23.0332C19.4711 23.3082 19.6961 23.5332 19.9711 23.5332C20.2461 23.5332 20.4711 23.3082 20.4711 23.0332V22.2129C20.9981 22.1209 22.5941 21.6858 22.5941 19.5488C22.5941 16.3428 19.1342 16.9543 19.1332 14.9883C19.1332 13.8613 19.755 13.8262 19.889 13.8262C20.085 13.8262 20.4957 13.9647 20.6527 14.7227C20.7347 15.1167 21.0515 15.416 21.4535 15.416H21.7132C22.2372 15.416 22.6163 14.9222 22.5023 14.4102C22.1173 12.6812 20.7686 12.3714 20.4906 12.3184V11.5C20.4906 11.225 20.2656 11 19.9906 11Z"
                  fill="currentColor"
                  fill-opacity="currentOpacity"
                />
                <path
                  opacity="0.4"
                  d="M5 3C3.895 3 3 3.895 3 5V19C3 20.105 3.895 21 5 21H14C14.552 21 15 20.552 15 20V15C15 13.523 15.809 12.2477 17 11.5547V11C17 9.895 17.895 9 19 9H20C20.552 9 21 8.552 21 8V5C21 3.895 20.105 3 19 3H5ZM10 6H14C14.552 6 15 6.448 15 7C15 7.552 14.552 8 14 8H10C9.448 8 9 7.552 9 7C9 6.448 9.448 6 10 6Z"
                  fill="var(--primary-font-color)"
                  fill-opacity="0.4"
                />
              </svg>
              Tutorials
            </li>
            <li className="button-manager-mobile">
              <div>
                <span onClick={() => setIsDarkMode(!isDark)}>{isDark ? <IoSunny /> : <FaMoon />}</span>
                <span onClick={displayHome}>
                  <IoHomeSharp />
                </span>
                <span
                  onClick={() => {
                    handleMenuClick(99)
                    disableSidebar()
                  }}
                >
                  <FaCircleUser />
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default SidebarDashboard
