import React, { Component } from 'react'
import './Expertise.css'
import '@fontsource/poppins'
import { GiPencilRuler } from 'react-icons/gi'
import { SlBookOpen } from 'react-icons/sl'
import { MdOutlineQueryStats } from 'react-icons/md'
import { RiGuideLine } from 'react-icons/ri'
import { FaSearch } from 'react-icons/fa'
import { FaCompass } from 'react-icons/fa'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Navigation, Pagination, Autoplay, Virtual } from 'swiper/modules'
import 'swiper/css'

export default class Expertise extends Component {
  render() {
    return (
      <div className="expertise" id="Expertise" data-aos="fade-right">
        <div className="title-expertise">
          <h1>Pourquoi Nous</h1>
          <p>
            Découvrez pourquoi notre expertise inégalée et notre sélection rigoureuse font de nous le choix idéal pour
            vos investissements en cryptomonnaies. <br></br> Bénéficiez d'un accompagnement personnalisé pour maximiser
            votre succès financier.
          </p>
        </div>
        <div className="content-expertise">
          <div className="card">
            <div>
              <FaCompass className="image-card" />
            </div>
            <h2>Explorer</h2>
            <p>
              Votre accès privilégié aux meilleures cryptomonnaies low cap, rigoureusement sélectionnées pour leurs
              projets prometteurs
            </p>
          </div>
          <div className="card">
            <div>
              <RiGuideLine className="image-card" />
            </div>
            <h2>Tendances</h2>
            <p>
              Un guide d'experts pour vous tenir informé des dernières tendances en cryptomonnaies, afin de prendre des
              décisions éclairées et opportunes.
            </p>
          </div>
          <div className="card">
            <div>
              <FaSearch className="image-card" />
            </div>
            <h2>Trouver</h2>
            <p>
              Accédez à une mine d'informations précieuses pour vous positionner sur les futures pépites des
              cryptomonnaies
            </p>
          </div>
        </div>
        <Swiper
          modules={[Virtual, Pagination, Autoplay]}
          spaceBetween={30}
          slidesPerView={1}
          pagination={{ clickable: true }}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          className="content-expertise-swiper"
        >
          <SwiperSlide>
            <div className="card">
              <div>
                <FaCompass className="image-card" />
              </div>
              <h2>Explorer</h2>
              <p>Votre point d'accès aux meilleures cryptos sélectionnées.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="card">
              <div>
                <RiGuideLine className="image-card" />
              </div>
              <h2>Guider</h2>
              <p>Un guide d'experts pour des décisions avisées en crypto-monnaie.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="card">
              <div>
                <FaSearch className="image-card" />
              </div>
              <h2>Trouver</h2>
              <p>Une mine d'informations précieuses pour affiner vos choix en cryptomonnaie.</p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    )
  }
}
