import React, { useEffect, useState } from 'react'
import '../CapsDashboard/MarketCap.css'
import { GoArrowUpRight } from 'react-icons/go'
import { GoArrowDownLeft } from 'react-icons/go'
import TopNavbarDashboard from '../../TopNavbarDashboard/TopNavbarDashboard'
import './MonthCaps.css'
import { Sparklines, SparklinesLine } from 'react-sparklines'

const MonthCaps = ({ handleIndexChange }) => {
  const [lowCaps, setLowCaps] = useState(null)

  const NavigateToCrypto = (symbol) => {
    const crypto = {
      symbol: symbol,
    }

    localStorage.setItem('crypto', JSON.stringify(crypto))
    handleIndexChange(3)
  }

  useEffect(() => {
    fetch('/api/month-caps-data', {
      method: 'GET',
    })
      .then(async (response) => {
        const data = await response.json()

        setLowCaps(data)
        console.log(data)
      })
      .catch((error) => {
        console.log('Retrieving of low caps:' + error)
      })
  }, [])

  function formatMarketCap(marketCap) {
    if (marketCap >= 1000000000000) {
      return (marketCap / 1000000000000).toFixed(2) + 'B'
    } else if (marketCap >= 1000000000) {
      return (marketCap / 1000000000).toFixed(2) + 'B'
    } else if (marketCap >= 1000000) {
      return (marketCap / 1000000).toFixed(2) + 'M'
    } else if (marketCap >= 1000) {
      return (marketCap / 1000).toFixed(2) + 'K'
    } else {
      return marketCap.toFixed(2)
    }
  }

  return (
    <div className="landing-page-dashboard">
      <TopNavbarDashboard text={'Coin Spy'} handleIndexChange={handleIndexChange} />
      <div className="landing-page-content">
        <div className="coinspy-content">
          <h2>Cryptomonnaies</h2>
          <div className="table-container-coinspy">
            <table>
              <thead>
                <tr>
                  <th>Monnaie</th>
                  <th>Prix</th>
                  <th>MarketCap</th>
                  <th>Volume 24h</th>
                  <th>7 Days Change</th>
                  <th>7 Days Market</th>
                  <th>24h Change</th>
                </tr>
              </thead>
              <tbody>
                {lowCaps
                  ? lowCaps.map((lowCap, index) => (
                      <tr key={index}>
                        <td className="symbol-content-coinspy" onClick={() => NavigateToCrypto(lowCap.id)}>
                          <img src={lowCap.image}></img>
                          {lowCap.symbol.toUpperCase()}
                        </td>
                        <td>{lowCap.current_price}€</td>
                        <td>{lowCap.market_cap ? formatMarketCap(lowCap.market_cap) : 0}</td>
                        <td>{lowCap.volume_24h ? formatMarketCap(lowCap.volume_24h) : 0}</td>
                        {lowCap.price_change_percentage_7d > 0 ? (
                          <td className="positive-change">
                            <div>
                              <GoArrowUpRight />
                            </div>
                            +{lowCap.price_change_percentage_7d ? lowCap.price_change_percentage_7d.toFixed(2) : 0}%
                          </td>
                        ) : (
                          <td className="negative-change">
                            <div>
                              <GoArrowDownLeft />
                            </div>
                            {lowCap.price_change_percentage_7d ? lowCap.price_change_percentage_7d.toFixed(2) : 0}%
                          </td>
                        )}
                        {lowCap.price_change_percentage_7d > 0 ? (
                          <td className="sparklines">
                            <Sparklines data={lowCap.sparkline} width={100} height={20}>
                              <SparklinesLine color="rgba(0, 188, 120, 1)" style={{ fill: 'none' }} />
                            </Sparklines>
                          </td>
                        ) : (
                          <td className="sparklines">
                            <Sparklines data={lowCap.sparkline} width={100} height={20}>
                              <SparklinesLine color="rgba(255, 80, 101, 1)" style={{ fill: 'none' }} />
                            </Sparklines>
                          </td>
                        )}
                        {lowCap.price_change_percentage_24h > 0 ? (
                          <td className="positive-change">
                            <div>
                              <GoArrowUpRight />
                            </div>
                            +{lowCap.price_change_percentage_24h ? lowCap.price_change_percentage_24h.toFixed(2) : 0}%
                          </td>
                        ) : (
                          <td className="negative-change">
                            <div>
                              <GoArrowDownLeft />
                            </div>
                            {lowCap.price_change_percentage_24h ? lowCap.price_change_percentage_24h.toFixed(2) : 0}%
                          </td>
                        )}
                      </tr>
                    ))
                  : ' '}
              </tbody>
            </table>
          </div>
        </div>
        <div className="coinspy-text">
          <h2>Coin Spy - Cryptomonnaies</h2>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
            industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and
            typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an
            unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
          </p>
        </div>
      </div>
      <div className="footer-mobile"></div>
    </div>
  )
}

export default MonthCaps
