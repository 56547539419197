import React, { useEffect, useState } from 'react'
import './Dashboard.css'
import DeniedPage from '../ErrorPage/DeniedPage'
import { SidebarDashboard } from '../NavbarDashboard/SidebarDashboard'
import { Toaster } from 'sonner'
import TopNavbarDashboard from '../TopNavbarDashboard/TopNavbarDashboard'
import LandingPageDashboard from '../DashboardPages/LandingPageDashboard/LandingPageDashboard'
import FAQDashboard from '../DashboardPages/FAQDashboard/FAQDashboard'
import LowMarketCap from '../DashboardPages/CapsDashboard/LowMarketCap'
import MediumMarketCap from '../DashboardPages/CapsDashboard/MediumMarketCap'
import HighMarketCap from '../DashboardPages/CapsDashboard/HighMarketCap'
import MonthCaps from '../DashboardPages/MonthCaps/MonthCaps'
import EBook from '../DashboardPages/E-Book/EBook'
import Profile from '../DashboardPages/Profile/Profile'
import WalletsDashboard from '../DashboardPages/WalletsDashboard/WalletsDashboard'
import Tutorials from '../DashboardPages/Tutorials/Tutorials'
import Closeout from '../DashboardPages/Closeout/Closeout'
import Tutorial1 from '../DashboardPages/TutorialsPages/Tutorial1'
import Tutorial2 from '../DashboardPages/TutorialsPages/Tutorial2'
import Tutorial3 from '../DashboardPages/TutorialsPages/Tutorial3'
import Tutorial4 from '../DashboardPages/TutorialsPages/Tutorial4'
import Tutorial5 from '../DashboardPages/TutorialsPages/Tutorial5'
import Tutorial6 from '../DashboardPages/TutorialsPages/Tutorial6'

const Dashboard = ({ handleIndexChange, activeIndex }) => {
  const [user, setUser] = useState(null)

  useEffect(() => {
    document.title = 'CoinFinder - Dashboard'

    setUser(JSON.parse(localStorage.getItem('user')))
  }, [])

  return (
    <>
      {!user || user.subscription === 'none' ? (
        <DeniedPage />
      ) : (
        <div className="dashboard">
          <div className="dashboard-content">
            <div className="main-page-content">
              <SidebarDashboard onIndexChange={handleIndexChange} />
              {activeIndex === 0 ? <LandingPageDashboard handleIndexChange={handleIndexChange} /> : ''}
              {activeIndex === 1 ? <HighMarketCap handleIndexChange={handleIndexChange} /> : ''}
              {activeIndex === 2 ? <MonthCaps handleIndexChange={handleIndexChange} /> : ''}
              {activeIndex === 3 ? <Closeout handleIndexChange={handleIndexChange} /> : ''}
              {activeIndex === 4 ? <EBook handleIndexChange={handleIndexChange} /> : ''}
              {activeIndex === 5 ? <Tutorials handleIndexChange={handleIndexChange} /> : ''}
              {activeIndex === 6 ? <FAQDashboard handleIndexChange={handleIndexChange} /> : ''}
              {activeIndex === 99 ? <Profile handleIndexChange={handleIndexChange} /> : ''}
              {activeIndex === 101 ? <Tutorial1 handleIndexChange={handleIndexChange} /> : ''}
              {activeIndex === 102 ? <Tutorial2 handleIndexChange={handleIndexChange} /> : ''}
              {activeIndex === 103 ? <Tutorial3 handleIndexChange={handleIndexChange} /> : ''}
              {activeIndex === 104 ? <Tutorial4 handleIndexChange={handleIndexChange} /> : ''}
              {activeIndex === 105 ? <Tutorial5 handleIndexChange={handleIndexChange} /> : ''}
              {activeIndex === 106 ? <Tutorial6 handleIndexChange={handleIndexChange} /> : ''}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Dashboard
