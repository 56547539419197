import React from 'react'
import TopNavbarDashboard from '../../TopNavbarDashboard/TopNavbarDashboard'
import './TutorialPages.css'

const Tutorial3 = ({ handleIndexChange }) => {
  return (
    <div className="landing-page-dashboard">
      <TopNavbarDashboard text={'Tutoriels'} handleIndexChange={handleIndexChange} />
      <div className="landing-page-tutorial">
        <h2>Trading de Cryptomonnaies</h2>

        <h3>Introduction au Trading de Cryptomonnaies</h3>
        <p>
          Le trading de cryptomonnaies consiste à acheter et vendre des cryptomonnaies dans le but de réaliser un
          profit. Contrairement à l'investissement à long terme, le trading se concentre sur les mouvements de prix à
          court terme.
        </p>

        <h3>Choisir une Plateforme de Trading</h3>
        <ul>
          <li>
            <strong>Coinbase Pro :</strong> Convient aux débutants avec une interface conviviale.
          </li>
          <li>
            <strong>Binance :</strong> Offre une large variété de cryptomonnaies et des frais de transaction bas.
          </li>
          <li>
            <strong>Kraken :</strong> Sécurité renforcée et options de trading avancées.
          </li>
        </ul>

        <h3>Types d'Ordres</h3>
        <ul>
          <li>
            <strong>Ordre au Marché :</strong> Achète ou vend immédiatement au prix actuel du marché.
          </li>
          <li>
            <strong>Ordre Limite :</strong> Achète ou vend à un prix spécifique ou mieux.
          </li>
          <li>
            <strong>Ordre Stop-Loss :</strong> Vendre automatiquement lorsque le prix atteint un certain seuil pour
            limiter les pertes.
          </li>
        </ul>

        <h3>Stratégies de Trading</h3>
        <ul>
          <li>
            <strong>Day Trading :</strong> Achat et vente de cryptomonnaies dans la même journée pour profiter des
            petites fluctuations de prix.
          </li>
          <li>
            <strong>Swing Trading :</strong> Tirer parti des fluctuations de prix sur plusieurs jours ou semaines.
          </li>
          <li>
            <strong>Scalping :</strong> Réaliser des profits sur des petites variations de prix en effectuant plusieurs
            transactions dans une même journée.
          </li>
        </ul>

        <h3>Analyse Technique et Fondamentale</h3>
        <ul>
          <li>
            <strong>Analyse Technique :</strong> Utilisation de graphiques et d'indicateurs pour prédire les mouvements
            de prix.
            <ul>
              <li>Moyennes mobiles</li>
              <li>Indice de force relative (RSI)</li>
              <li>Bandes de Bollinger</li>
            </ul>
          </li>
          <li>
            <strong>Analyse Fondamentale :</strong> Évaluation de la valeur intrinsèque d'une cryptomonnaie en examinant
            des facteurs tels que l'équipe de développement, l'adoption et la technologie.
          </li>
        </ul>

        <h3>Gestion des Risques</h3>
        <ul>
          <li>
            <strong>Diversification :</strong> Ne pas mettre tous vos fonds dans une seule cryptomonnaie.
          </li>
          <li>
            <strong>Utilisation de Stop-Loss :</strong> Protéger votre capital en limitant les pertes potentielles.
          </li>
          <li>
            <strong>Ne jamais investir plus que ce que vous pouvez vous permettre de perdre :</strong> Le marché des
            cryptomonnaies est volatil et risqué.
          </li>
        </ul>

        <h3>Outils de Trading</h3>
        <ul>
          <li>
            <strong>Trading Bots :</strong> Automatiser les stratégies de trading pour exécuter des transactions basées
            sur des critères prédéfinis.
          </li>
          <li>
            <strong>Applications Mobiles :</strong> Surveiller le marché et exécuter des transactions en déplacement.
          </li>
          <li>
            <strong>Tableaux de Bord :</strong> Utiliser des outils comme CoinMarketCap et TradingView pour suivre les
            prix et les tendances du marché.
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Tutorial3
