import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Toaster, toast } from 'sonner'
import './Login.css'
import background from '../../assets/login-background.png'
import coin from '../../assets/coin42px.png'
import { FaEye } from 'react-icons/fa'
import { FaEyeSlash } from 'react-icons/fa'

const Login = () => {
  const navigate = useNavigate()
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  })

  async function logInUser() {
    fetch('/api/user/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then(async (response) => {
        const data = await response.json()
        let userData = data

        if (data.message) {
          toast.error(data.message)
        }

        const emailUser = data.user_email
        let idUser = 0

        fetch(`/api/user/retrieve/email/${emailUser}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then(async (response) => {
            const data = await response.json()

            if (data.length > 0) {
              idUser = data[0].id
            }

            if (idUser !== 0) {
              fetch(`/api/user/metadata/${idUser}`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                },
              })
                .then(async (response) => {
                  const data = await response.json()

                  if (data.data.user_meta.email_confirmed[0] === 'not-confirmed') {
                    toast.error('Vous devez vérifier votre email pour vous connecter !')
                    return
                  }

                  userData.subscription = data.data.user_meta.subscription[0]
                  userData.id = idUser
                  userData.customer_stripe_id = data.data.user_meta.customer_stripe_id[0]
                  localStorage.setItem('user', JSON.stringify(userData))

                  const user = JSON.parse(localStorage.getItem('user'))
                  toast.success(user.user_display_name + ' connecté !')
                  if (user.subscription !== 'none') {
                    navigate('/dashboard')
                  } else {
                    navigate('/')
                  }
                })
                .catch((error) => {
                  console.log(error)
                })
            }
          })
          .catch((error) => {
            console.log(error)
          })
      })
      .catch((error) => {
        console.error('Server internal error')
      })
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const displayHome = () => {
    navigate('/')
  }

  const displayRegister = () => {
    navigate('/register')
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    logInUser()
  }

  const displayPasswordForgotten = () => {
    navigate('/send-reset')
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }
  return (
    <>
      <Toaster position="top-left" className="toaster-app" expand={false} richColors />
      <div className="login">
        <div className="content-img-mobile">
          <img className="background-image" src={background} alt="login-image" />
          <div onClick={displayHome}>
            <img src={coin} alt="coin-logo"></img>
            <a className="logo-login">CoinFinder</a>
          </div>
        </div>
        <div className="content-login">
          <div className="login-title">
            <h1>Se Connecter</h1>
            <p>Entrez votre e-mail et votre mot de passe pour vous connecter!</p>
          </div>
          <div className="login-form">
            <form id="login-form" onSubmit={handleSubmit}>
              <p>
                Pseudonyme<span>*</span>
              </p>
              <input type="text" placeholder="Johndoe" name="username" onChange={handleChange} />
              <p>
                Mot de passe<span>*</span>
              </p>
              <div className="password-box">
                <input
                  type={passwordVisible ? 'text' : 'password'}
                  placeholder="******"
                  name="password"
                  onChange={handleChange}
                />
                {passwordVisible ? (
                  <FaEye onClick={togglePasswordVisibility} />
                ) : (
                  <FaEyeSlash onClick={togglePasswordVisibility} />
                )}
              </div>
              <div className="stay-connected">
                <label>
                  <input type="checkbox" />
                  Rester connecté
                </label>
                <a onClick={displayPasswordForgotten}>Mot de passe oublié ?</a>
              </div>
              <button type="submit">Se connecter</button>
            </form>
            <div className="create-account">
              <p>Vous n'avez pas de compte ?</p>
              <a onClick={displayRegister}>Créer un compte</a>
            </div>
          </div>
        </div>
        <div className="content-img">
          <img className="background-image" src={background} alt="login-image" />
          <div onClick={displayHome}>
            <img src={coin} alt="coin-logo"></img>
            <a className="logo-login">
              <br></br>CoinFinder
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
