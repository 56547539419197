import React, { useEffect } from 'react'
import './TopNavbarDashboard.css'
import { useNavigate } from 'react-router-dom'
import { FaMoon } from 'react-icons/fa'
import { IoSunny } from 'react-icons/io5'
import { FaCircleUser } from 'react-icons/fa6'
import { IoHomeSharp } from 'react-icons/io5'
import useLocalStorage from 'use-local-storage'

const TopNavbarDashboard = ({ text, handleIndexChange }) => {
  const navigate = useNavigate()
  const [isDark, setIsDark] = useLocalStorage('darkmode')

  const handleLogoClick = () => {
    navigate('/')
  }

  return (
    <div className="top-navbar-dashboard">
      <div className="navbar-title">{text}</div>
      <div className="top-navbar-button">
        <div className="top-navbar-icon" onClick={() => setIsDark(!isDark)}>
          {isDark ? <FaMoon /> : <IoSunny />}
        </div>
        <div className="top-navbar-icon">
          <IoHomeSharp onClick={handleLogoClick} />
        </div>
        <div className="top-navbar-icon">
          <FaCircleUser onClick={() => handleIndexChange(99)} />
        </div>
      </div>
    </div>
  )
}

export default TopNavbarDashboard
