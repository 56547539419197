import React, { Component } from 'react'
import './MiddleContent.css'
import card from '../../assets/hero-card-dark.png'

export default class MiddleContent extends Component {
  render() {
    return (
      <div className="middle-content" data-aos="fade-left">
        <div className="middle-content-content">
          <div className="content-text">
            <h1>
              Plongez dans <br></br> l'avenir financier<br></br>
            </h1>
            <p>
              Explorez les vastes possibilités offertes par les cryptomonnaies à faible capitalisation et prenez en main
              votre avenir financier dès aujourd'hui. Accédez à des informations exclusives et découvrez des
              opportunités d'investissement innovantes, sélectionnées par nos experts. Transformez vos ambitions en
              réalité avec des données fiables et des analyses approfondies.
            </p>
          </div>
          <div className="content-image">
            <img src={card} alt="card-dark image"></img>
          </div>
          <div className="content-image-mobile">
            <h1>Plongez dans l'avenir financier</h1>
            <div>
              <div className="content-mobile">
                <p>
                  Explorez les possibilités infinies des cryptomonnaies et prenez le contrôle de votre avenir <br></br>
                  financier dès aujourd'hui.
                  <br></br>Découvrez des opportunités d'investissement innovantes et transformez vos aspirations en
                  réalité.
                </p>
              </div>
              <img src={card} alt="card-dark image"></img>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
