import React, { useState, Component } from 'react'
import './Faq.css'
import { IoIosArrowDown } from 'react-icons/io'
import Accordion from '../Accordion/Accordion'

const Faq = () => {
  const [selected, setSelected] = useState(null)

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null)
    }

    setSelected(i)
  }

  return (
    <div className="faq" id="FAQ">
      <div className="faq-title">
        <h1>Questions Fréquentes</h1>
        <p>
          Trouvez des réponses aux questions fréquemment posées sur les cryptomonnaies, nos services et comment
          démarrer. Parcourez notre FAQ complète pour obtenir clarté et confiance dans vos décisions.
        </p>
      </div>
      <div className="accordion-list">
        <div className="item">
          <div className="title-item" onClick={() => toggle(0)}>
            <h2>Qu'est-ce qui différencie Coinfinder des autres plateformes de suivi de cryptomonnaies ?</h2>
            <span>
              {selected === 0 ? (
                <span className="arrow-down-selected">
                  <IoIosArrowDown />
                </span>
              ) : (
                <span className="arrow-down-unselected">
                  <IoIosArrowDown />
                </span>
              )}
            </span>
          </div>
          <div className={selected === 0 ? 'content show' : 'content'}>
            Notre différence réside dans la sélection rigoureuse des meilleures cryptos, garantie par des experts. En
            outre, notre plateforme offre une expérience utilisateur plus fiable et de meilleure qualité, éliminant les
            rumeurs et informations non vérifiées souvent présentes sur les groupes Telegram.
          </div>
        </div>
        <div className="item">
          <div className="title-item" onClick={() => toggle(1)}>
            <h2>
              Comment Coinfinder sélectionne-t-il les meilleures cryptomonnaies à faible capitalisation boursière ?
            </h2>
            <span>
              {selected === 1 ? (
                <span className="arrow-down-selected">
                  <IoIosArrowDown />
                </span>
              ) : (
                <span className="arrow-down-unselected">
                  <IoIosArrowDown />
                </span>
              )}
            </span>
          </div>
          <div className={selected === 1 ? 'content show' : 'content'}>
            CoinFinder sélectionne les meilleures crypto-monnaies à faible capitalisation boursière grâce à une
            méthodologie rigoureuse. Notre équipe d'experts analyse divers facteurs tels que le projet, la technologie,
            l'équipe de développement, l'adoption par la communauté et les perspectives de croissance. En combinant ces
            données avec des analyses de marché en temps réel, nous identifions les crypto-monnaies offrant un fort
            potentiel de croissance.
          </div>
        </div>
        <div className="item">
          <div className="title-item" onClick={() => toggle(2)}>
            <h2>Quels types d'informations puis-je trouver sur Coinfinder concernant chaque cryptomonnaie ?</h2>
            <span>
              {selected === 2 ? (
                <span className="arrow-down-selected">
                  <IoIosArrowDown />
                </span>
              ) : (
                <span className="arrow-down-unselected">
                  <IoIosArrowDown />
                </span>
              )}
            </span>
          </div>
          <div className={selected === 2 ? 'content show' : 'content'}>
            Sur CoinFinder, vous trouverez une multitude d'informations sur chaque cryptomonnaie. Cela comprend des
            détails sur le projet, comme son objectif et sa technologie, ainsi que des données financières en temps réel
            telles que le prix, la capitalisation boursière et le volume d'échanges. De plus, nous fournissons des
            analyses sur les tendances du marché et des évaluations de la viabilité et du potentiel de croissance de
            chaque cryptomonnaie. En résumé, vous disposez de toutes les informations nécessaires pour prendre des
            décisions éclairées sur vos cryptomonnaies sur CoinFinder.
          </div>
        </div>
        <div className="item">
          <div className="title-item" onClick={() => toggle(3)}>
            <h2>Comment puis-je utiliser Coinfinder pour prendre des décisions d'investissement éclairées ?</h2>
            <span>
              {selected === 3 ? (
                <span className="arrow-down-selected">
                  <IoIosArrowDown />
                </span>
              ) : (
                <span className="arrow-down-unselected">
                  <IoIosArrowDown />
                </span>
              )}
            </span>
          </div>
          <div className={selected === 3 ? 'content show' : 'content'}>
            Coinfinder fournit aux utilisateurs des outils et des ressources pour les aider à comprendre les
            cryptomonnaies à faible capitalisation boursière et à évaluer leur potentiel d'investissement. Vous pouvez
            utiliser les données et les analyses fournies sur la plateforme pour prendre des décisions informées et
            élaborer des stratégies d'investissement adaptées à vos objectifs et à votre profil de risque.
          </div>
        </div>
      </div>
    </div>
  )
}

export default Faq
