import { React, useState } from 'react'
import './FAQDashboard.css'
import { IoIosArrowDown } from 'react-icons/io'
import TopNavbarDashboard from '../../TopNavbarDashboard/TopNavbarDashboard'

const FAQDashboard = ({ handleIndexChange }) => {
  const [selected, setSelected] = useState(null)

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null)
    }

    setSelected(i)
  }

  return (
    <div className="landing-page-dashboard">
      <TopNavbarDashboard text={'Ressources'} handleIndexChange={handleIndexChange} />
      <div className="landing-page-content">
        <div className="accordion-list-items">
          <div className="item">
            <div className="title-item" onClick={() => toggle(0)}>
              <h2>Qu'est-ce qu'une cryptomonnaie ?</h2>
              <span>
                {selected === 0 ? (
                  <span className="arrow-down-selected">
                    <IoIosArrowDown />
                  </span>
                ) : (
                  <span className="arrow-down-unselected">
                    <IoIosArrowDown />
                  </span>
                )}
              </span>
            </div>
            <div className={selected === 0 ? 'content show' : 'content'}>
              Une cryptomonnaie est une forme de monnaie numérique conçue pour fonctionner comme un moyen d'échange
              sécurisé à l'aide de la cryptographie. Contrairement aux monnaies traditionnelles, les cryptomonnaies ne
              dépendent pas d'une autorité centrale comme une banque centrale pour émettre de nouvelles unités ou gérer
              les transactions. Au lieu de cela, elles utilisent la technologie blockchain, un registre décentralisé et
              sécurisé, pour enregistrer toutes les transactions de manière transparente et immuable. Chaque transaction
              est vérifiée par un réseau de pairs (nodes) et ajoutée à un bloc qui est ensuite ajouté à la chaîne de
              blocs (blockchain), créant ainsi un historique permanent des transactions.
            </div>
          </div>
          <div className="item">
            <div className="title-item" onClick={() => toggle(1)}>
              <h2>Quelles sont les principales différences entre Bitcoin et Ethereum ?</h2>
              <span>
                {selected === 1 ? (
                  <span className="arrow-down-selected">
                    <IoIosArrowDown />
                  </span>
                ) : (
                  <span className="arrow-down-unselected">
                    <IoIosArrowDown />
                  </span>
                )}
              </span>
            </div>
            <div className={selected === 1 ? 'content show' : 'content'}>
              Bitcoin (BTC) est souvent considéré comme la première et la plus connue des cryptomonnaies. Il a été créé
              en 2009 par une personne ou un groupe de personnes sous le pseudonyme de Satoshi Nakamoto. Bitcoin est
              principalement utilisé comme une réserve de valeur numérique et un moyen de paiement, avec une offre
              limitée à 21 millions de BTC. Ethereum (ETH), lancé en 2015 par Vitalik Buterin, est une plateforme
              décentralisée qui permet aux développeurs de construire et de déployer des contrats intelligents (smart
              contracts) et des applications décentralisées (dApps). Ethereum utilise également une cryptomonnaie
              appelée Ether pour alimenter son réseau et payer les frais de transaction.
            </div>
          </div>
          <div className="item">
            <div className="title-item" onClick={() => toggle(2)}>
              <h2>Comment sécuriser ses cryptomonnaies ?</h2>
              <span>
                {selected === 2 ? (
                  <span className="arrow-down-selected">
                    <IoIosArrowDown />
                  </span>
                ) : (
                  <span className="arrow-down-unselected">
                    <IoIosArrowDown />
                  </span>
                )}
              </span>
            </div>
            <div className={selected === 2 ? 'content show' : 'content'}>
              La sécurité des cryptomonnaies est cruciale en raison de la nature irréversible des transactions et de la
              valeur élevée des actifs numériques. Voici quelques mesures pour sécuriser vos cryptomonnaies : Utilisez
              des wallets (portefeuilles) sécurisés : Choisissez un wallet de cryptomonnaie réputé et sécurisé, comme un
              hardware wallet (portefeuille matériel), qui stocke vos clés privées hors ligne. Sauvegardez vos clés
              privées : Conservez une copie sécurisée de vos clés privées dans un endroit sûr et hors ligne. Activez
              l'authentification à deux facteurs (2FA) : Renforcez la sécurité de vos comptes en activant
              l'authentification à deux facteurs sur vos wallets et comptes d'échange. Évitez les phishing et les scams
              : Soyez vigilant face aux tentatives de phishing et aux arnaques en ligne en vérifiant toujours l'URL du
              site web et en évitant de cliquer sur des liens non sécurisés ou suspects.
            </div>
          </div>
          <div className="item">
            <div className="title-item" onClick={() => toggle(3)}>
              <h2>Quels sont les risques associés aux cryptomonnaies ?</h2>
              <span>
                {selected === 3 ? (
                  <span className="arrow-down-selected">
                    <IoIosArrowDown />
                  </span>
                ) : (
                  <span className="arrow-down-unselected">
                    <IoIosArrowDown />
                  </span>
                )}
              </span>
            </div>
            <div className={selected === 3 ? 'content show' : 'content'}>
              Bien que les cryptomonnaies offrent des opportunités d'investissement potentiellement lucratives, elles
              présentent également plusieurs risques importants : Volatilité des prix : Les prix des cryptomonnaies
              peuvent fluctuer considérablement en raison de divers facteurs, y compris la spéculation du marché et les
              événements économiques. Sécurité : Les wallets et les plateformes d'échange peuvent être vulnérables aux
              cyberattaques et aux piratages, entraînant la perte de fonds. Régulation : Les régulations
              gouvernementales peuvent affecter le marché des cryptomonnaies, introduisant des incertitudes et des
              restrictions potentielles. Scams et fraudes : L'écosystème des cryptomonnaies est également susceptible
              d'être infiltré par des arnaques et des projets frauduleux, nécessitant une diligence raisonnable avant
              d'investir.
            </div>
          </div>
          <div className="item">
            <div className="title-item" onClick={() => toggle(4)}>
              <h2>Comment choisir une cryptomonnaie à faible capitalisation prometteuse ?</h2>
              <span>
                {selected === 4 ? (
                  <span className="arrow-down-selected">
                    <IoIosArrowDown />
                  </span>
                ) : (
                  <span className="arrow-down-unselected">
                    <IoIosArrowDown />
                  </span>
                )}
              </span>
            </div>
            <div className={selected === 4 ? 'content show' : 'content'}>
              Investir dans des cryptomonnaies à faible capitalisation peut être risqué mais aussi potentiellement
              lucratif. Voici quelques critères à considérer pour choisir une cryptomonnaie prometteuse : Solvabilité du
              projet : Évaluez la solidité du projet sous-jacent, y compris son cas d'utilisation, son équipe de
              développement, et sa feuille de route. Technologie et innovation : Recherchez des projets utilisant des
              technologies innovantes avec un avantage compétitif clair sur le marché. Adoption et communauté : Une
              communauté active et une adoption croissante peuvent indiquer un intérêt durable pour la cryptomonnaie.
              Analyse fondamentale : Utilisez des analyses fondamentales pour évaluer la valeur intrinsèque de la
              cryptomonnaie, y compris ses métriques financières et son potentiel de croissance.
            </div>
          </div>
          <div className="item">
            <div className="title-item" onClick={() => toggle(5)}>
              <h2>Qu'est-ce qu'un wallet (portefeuille) de cryptomonnaie et comment en choisir un ?</h2>
              <span>
                {selected === 5 ? (
                  <span className="arrow-down-selected">
                    <IoIosArrowDown />
                  </span>
                ) : (
                  <span className="arrow-down-unselected">
                    <IoIosArrowDown />
                  </span>
                )}
              </span>
            </div>
            <div className={selected === 5 ? 'content show' : 'content'}>
              Un wallet de cryptomonnaie est un logiciel ou un dispositif physique qui permet aux utilisateurs de
              stocker, envoyer et recevoir des cryptomonnaies de manière sécurisée. Il existe plusieurs types de wallets
              : Wallets matériel (hardware wallets) : Des dispositifs physiques sécurisés comme Ledger Nano S ou Trezor,
              qui stockent vos clés privées hors ligne. Wallets logiciel (software wallets) : Applications de wallet
              téléchargeables sur votre ordinateur ou smartphone, comme Electrum pour Bitcoin ou MyEtherWallet pour
              Ethereum. Wallets en ligne (online wallets) : Wallets hébergés sur des plateformes d'échange ou des
              services en ligne, avec un niveau de sécurité variable. Wallets papier (paper wallets) : Clés privées
              imprimées sur papier et stockées physiquement, généralement comme une sauvegarde à froid.
            </div>
          </div>
          <div className="item">
            <div className="title-item" onClick={() => toggle(6)}>
              <h2>Qu'est-ce qu'un airdrop en cryptomonnaie et comment en profiter ?</h2>
              <span>
                {selected === 6 ? (
                  <span className="arrow-down-selected">
                    <IoIosArrowDown />
                  </span>
                ) : (
                  <span className="arrow-down-unselected">
                    <IoIosArrowDown />
                  </span>
                )}
              </span>
            </div>
            <div className={selected === 6 ? 'content show' : 'content'}>
              Un airdrop est une distribution gratuite de tokens cryptographiques à des détenteurs existants de
              certaines cryptomonnaies. Les airdrops sont souvent utilisés par les projets de cryptomonnaies pour
              accroître leur visibilité et distribuer leurs tokens à une communauté existante. Pour participer à un
              airdrop, vous devez souvent remplir certaines conditions préalables, telles que détenir un montant minimum
              de la cryptomonnaie mère ou s'inscrire sur une plateforme spécifique. Il est recommandé de suivre les
              canaux officiels du projet pour les annonces d'airdrops et de vérifier les détails et les dates limites
              pour participer.
            </div>
          </div>
          <div className="item">
            <div className="title-item" onClick={() => toggle(7)}>
              <h2>Quelles sont les perspectives futures des cryptomonnaies ?</h2>
              <span>
                {selected === 7 ? (
                  <span className="arrow-down-selected">
                    <IoIosArrowDown />
                  </span>
                ) : (
                  <span className="arrow-down-unselected">
                    <IoIosArrowDown />
                  </span>
                )}
              </span>
            </div>
            <div className={selected === 7 ? 'content show' : 'content'}>
              Quelles sont les perspectives futures des cryptomonnaies ? Les perspectives futures des cryptomonnaies
              semblent prometteuses avec plusieurs développements attendus : Intégration accrue dans les systèmes
              financiers : L'acceptation croissante des cryptomonnaies par les banques et les institutions financières
              traditionnelles. Développement de la DeFi (Finance décentralisée) : L'émergence de solutions financières
              autonomes et décentralisées, permettant un accès plus large aux services financiers. Interconnexion des
              blockchains : L'amélioration de l'interopérabilité entre différentes blockchains pour faciliter l'échange
              d'actifs et de données. Adoption généralisée : Une adoption accrue par le grand public et les entreprises,
              conduisant à une utilisation plus répandue et une valeur accrue pour les cryptomonnaies. Innovation
              continue : L'émergence de nouvelles applications et utilisations des technologies blockchain, y compris
              les contrats intelligents et les tokens programmables.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FAQDashboard
