import React from 'react'
import TopNavbarDashboard from '../../TopNavbarDashboard/TopNavbarDashboard'
import './TutorialPages.css'

const Tutorial5 = ({ handleIndexChange }) => {
  return (
    <div className="landing-page-dashboard">
      <TopNavbarDashboard text={'Tutoriels'} handleIndexChange={handleIndexChange} />
      <div className="landing-page-tutorial">
        <h2>Les ICO et les IEO</h2>

        <h3>Introduction aux ICO et IEO</h3>
        <p>
          Les ICO et IEO sont des méthodes de levée de fonds pour de nouveaux projets de cryptomonnaies. Elles
          permettent aux entreprises de collecter des fonds en émettant de nouveaux tokens.
        </p>

        <h3>Fonctionnement des ICO</h3>
        <ul>
          <li>
            <strong>Concept :</strong> Une entreprise propose de nouveaux tokens en échange de cryptomonnaies établies
            comme Bitcoin ou Ethereum.
          </li>
          <li>
            <strong>Livre Blanc :</strong> Un document détaillant le projet, l'équipe, les objectifs et l'utilisation
            des fonds levés.
          </li>
          <li>
            <strong>Phase de Vente :</strong> La période pendant laquelle les investisseurs peuvent acheter des tokens.
          </li>
        </ul>

        <h3>Fonctionnement des IEO</h3>
        <ul>
          <li>
            <strong>Concept :</strong> Similaire aux ICO, mais l'offre est gérée par une plateforme d'échange de
            cryptomonnaies.
          </li>
          <li>
            <strong>Sélection des Projets :</strong> Les plateformes d'échange évaluent et sélectionnent les projets
            avant de les lister pour une IEO.
          </li>
          <li>
            <strong>Phase de Vente :</strong> Les investisseurs achètent des tokens directement sur la plateforme
            d'échange.
          </li>
        </ul>

        <h3>Avantages et Inconvénients</h3>
        <ul>
          <li>
            <strong>Avantages des ICO :</strong>
            <ul>
              <li>Facilité de levée de fonds.</li>
              <li>Accès direct aux investisseurs.</li>
              <li>Liberté de fixer les conditions de l'offre.</li>
            </ul>
          </li>
          <li>
            <strong>Inconvénients des ICO :</strong>
            <ul>
              <li>Risques élevés de fraude et d'escroquerie.</li>
              <li>Régulation incertaine.</li>
              <li>Projets souvent non vérifiés.</li>
            </ul>
          </li>
          <li>
            <strong>Avantages des IEO :</strong>
            <ul>
              <li>Sécurité renforcée grâce à la vérification des plateformes d'échange.</li>
              <li>Accès facilité aux investisseurs existants de la plateforme.</li>
              <li>Meilleure visibilité pour le projet.</li>
            </ul>
          </li>
          <li>
            <strong>Inconvénients des IEO :</strong>
            <ul>
              <li>Frais élevés imposés par les plateformes d'échange.</li>
              <li>Moins de contrôle pour l'entreprise émettrice.</li>
              <li>Dépendance à la réputation de la plateforme d'échange.</li>
            </ul>
          </li>
        </ul>

        <h3>Évaluation des Opportunités d'Investissement</h3>
        <ul>
          <li>
            <strong>Équipe de Développement :</strong> Vérifiez les antécédents et l'expérience de l'équipe.
          </li>
          <li>
            <strong>Livre Blanc :</strong> Analysez en détail le livre blanc pour comprendre le projet.
          </li>
          <li>
            <strong>Communauté :</strong> Rejoignez les forums et les groupes de discussion pour obtenir des avis
            d'autres investisseurs.
          </li>
          <li>
            <strong>Partenariats et Collaborations :</strong> Recherchez des partenariats solides avec des entreprises
            établies.
          </li>
        </ul>

        <h3>Participer à une ICO ou une IEO</h3>
        <ol>
          <li>
            <strong>Création de Compte :</strong> Inscrivez-vous sur la plateforme de l'ICO ou la plateforme d'échange
            pour une IEO.
          </li>
          <li>
            <strong>Vérification KYC :</strong> Fournissez les documents nécessaires pour la vérification d'identité.
          </li>
          <li>
            <strong>Acheter des Tokens :</strong> Suivez les instructions pour acheter des tokens pendant la phase de
            vente.
          </li>
          <li>
            <strong>Sécuriser les Tokens :</strong> Transférez les tokens achetés vers un portefeuille sécurisé.
          </li>
        </ol>

        <h3>Risques et Régulations</h3>
        <ul>
          <li>
            <strong>Réglementations :</strong> Soyez conscient des réglementations locales concernant les
            investissements en ICO et IEO.
          </li>
          <li>
            <strong>Escroqueries :</strong> Méfiez-vous des projets sans historique vérifiable ou sans informations
            claires.
          </li>
          <li>
            <strong>Volatilité :</strong> Les tokens issus des ICO et IEO peuvent être extrêmement volatils.
          </li>
        </ul>

        <h3>Conclusion</h3>
        <p>
          Les ICO et les IEO offrent des opportunités uniques d'investissement dans de nouveaux projets de
          cryptomonnaies. Cependant, ils comportent également des risques importants. Une évaluation approfondie et une
          compréhension claire des projets sont essentielles pour prendre des décisions d'investissement éclairées.
        </p>
      </div>
    </div>
  )
}

export default Tutorial5
