import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast, Toaster } from 'sonner'
import background from '../../assets/login-background.png'
import coin from '../../assets/coin42px.png'
import { FaEye } from 'react-icons/fa'
import { FaEyeSlash } from 'react-icons/fa'
import validator from 'validator'
import CryptoJS from 'crypto-js'

const Register = () => {
  const navigate = useNavigate()
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [passwordVisibleConfirm, setPasswordVisibleConfirm] = useState(false)
  const [creationUserError, setCreationUserError] = useState('')
  const [checked, setChecked] = useState(false)
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    passwordConfirm: '',
  })
  const [formValidationError, setValidationError] = useState(formData)
  const handleChecked = () => setChecked(!checked)

  function generateRandomBytes(length) {
    const randomBytes = CryptoJS.lib.WordArray.random(length)
    return randomBytes.toString(CryptoJS.enc.Hex)
  }

  async function addUser() {
    setCreationUserError('')

    fetch('/api/user/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then(async (response) => {
        const data = await response.json()

        if (data.error) {
          toast.error(data.error)
          return
        }

        const userId = data.ID
        const formToken = {
          email_token: generateRandomBytes(64),
        }

        fetch(`/api/user/metadata/${userId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formToken),
        }).then(async (response) => {
          const data = await response.json()

          const emailData = {
            to: formData.email,
            subject: 'CoinFinder - Confirmation de compte',
            text: `Bonjour ${formData.username},\n\nMerci de vous être inscrit sur CoinFinder !\n\nPour confirmer votre compte, veuillez cliquer sur le lien suivant : https://coinfinder.fr/validate-email?emailToken=${formToken.email_token}\n\nSi vous n'êtes pas à l'origine de cette inscription, veuillez ignorer cet email.\n\nCordialement,\nL'équipe CoinFinder`,
          }

          fetch('/api/send-email', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(emailData),
          }).then((response) => {
            if (response.ok) {
              return response.json()
            } else {
              throw new Error('Erreur lors de la requête')
            }
          })
        })

        toast.success(
          'Compte créé ! Un email à été envoyé à ' +
            formData.email +
            ' ! Veuillez confirmer votre compte avant de vous connecter.'
        )

        resetForm()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleSubmit = (e) => {
    console.log(formData)
    e.preventDefault()
    const validationErrors = {}

    if (formData.username === null || formData.username === '') {
      validationErrors.username = 'Pseudonyme est requis'
      toast.error('Pseudonyme requis')
      return
    }

    if (formData.email === null || formData.email === '') {
      validationErrors.email = 'Email est requis'
      toast.error('Email requis')
      return
    } else if (!validator.isEmail(formData.email)) {
      validationErrors.email = 'Format incorrect'
      toast.error("Format d'email incorrect")
      return
    }

    if (!formData.password === null || formData.password === '') {
      toast.error('Mot de passe requis')
      validationErrors.password = 'Mot de passe requis'
      return
    }

    if (
      formData.passwordConfirm === null ||
      formData.passwordConfirm === '' ||
      formData.password !== formData.passwordConfirm
    ) {
      validationErrors.passwordConfirm = 'Mots de passe ne correspondent pas'
      toast.error('Les mots de passe ne correspondent pas')
      return
    }

    if (!checked) {
      toast.error('Veuillez accepter les termes et conditions')
      return
    }

    if (
      !formValidationError.username &&
      !formValidationError.email &&
      !formValidationError.password &&
      !formValidationError.passwordConfirm &&
      checked
    ) {
      addUser(formData)
    }
  }

  const resetForm = () => {
    setFormData({
      username: '',
      email: '',
      password: '',
      passwordConfirm: '',
    })
    setChecked(false)

    navigate('/')
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const displayHome = () => {
    navigate('/')
  }

  const displayLogin = () => {
    navigate('/login')
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  const togglePasswordVisibilityConfirm = () => {
    setPasswordVisibleConfirm(!passwordVisibleConfirm)
  }

  return (
    <>
      <Toaster position="top-left" className="toaster-app" expand={false} richColors />
      <div className="login">
        <div className="content-img-mobile">
          <img className="background-image" src={background} alt="login-image" />
          <div onClick={displayHome}>
            <img src={coin} alt="coin-logo"></img>
            <a className="logo-login">CoinFinder</a>
          </div>
        </div>
        <div className="content-login">
          <div className="login-title">
            <h1>S'Inscrire</h1>
            <p>Entrez vos informations pour vous inscrire!</p>
          </div>
          <div className="login-form">
            <form id="login-form" onSubmit={handleSubmit}>
              <p>
                Pseudonyme<span>*</span>
              </p>
              <input
                type="text"
                placeholder="Johndoe"
                name="username"
                onChange={handleChange}
                value={formData.username}
              />
              <p>
                Email<span>*</span>
              </p>
              <input
                type="email"
                placeholder="johndoe@email.com"
                name="email"
                onChange={handleChange}
                value={formData.email}
              />
              <p>
                Mot de passe<span>*</span>
              </p>
              <div className="password-box">
                <input
                  type={passwordVisible ? 'text' : 'password'}
                  placeholder="******"
                  name="password"
                  onChange={handleChange}
                  value={formData.password}
                />
                {passwordVisible ? (
                  <FaEye onClick={togglePasswordVisibility} />
                ) : (
                  <FaEyeSlash onClick={togglePasswordVisibility} />
                )}
              </div>
              <p>
                Confirmer Mot de passe<span>*</span>
              </p>
              <div className="password-box">
                <input
                  type={passwordVisibleConfirm ? 'text' : 'password'}
                  placeholder="******"
                  name="passwordConfirm"
                  onChange={handleChange}
                  value={formData.passwordConfirm}
                />
                {passwordVisibleConfirm ? (
                  <FaEye onClick={togglePasswordVisibilityConfirm} />
                ) : (
                  <FaEyeSlash onClick={togglePasswordVisibilityConfirm} />
                )}
              </div>
              <div className="stay-connected">
                <label>
                  <input type="checkbox" name="terms" onClick={handleChecked} checked={checked} />
                  En cliquant, vous acceptez nos "Termes et Conditions"
                </label>
              </div>
              <button type="submit">S'Inscrire</button>
            </form>
            <div className="create-account">
              <p>Déjà un compte ?</p>
              <a onClick={displayLogin}>Se connecter</a>
            </div>
          </div>
        </div>
        <div className="content-img">
          <img className="background-image" src={background} alt="login-image" />
          <div onClick={displayHome}>
            <img src={coin} alt="coin-logo"></img>
            <a className="logo-login">
              <br></br>CoinFinder
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Register
