import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

export const SubscriptionSuccess = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const userAccount = JSON.parse(localStorage.getItem('user'))
    const paymentUrl = JSON.parse(localStorage.getItem('stripe_subscription'))

    fetch('/api/stripe-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userAccount),
    }).then(async (response) => {
      const data = await response.json()
      const customerId = data.customerId
      const user = JSON.parse(localStorage.getItem('user'))
      const priceId = JSON.parse(localStorage.getItem('stripe_subscription')).subscription

      if (data.success === 'completed') {
        fetch(`/api/user/retrieve/email/${user.user_email}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }).then(async (response) => {
          const data = await response.json()
          const formData = {
            subscription: 'active',
            customer_stripe_id: customerId,
          }
          let subscriptionLocal = ''

          switch (priceId) {
            case 'price_1P8GjARudVpbFJ6dDPgX3Kf8':
            case 'price_1P5PSmRudVpbFJ6dInFNiJIC':
              formData.subscription = 'subscription-debutant'
              subscriptionLocal = 'subscription-debutant'
              break
            case 'price_1P8Gl4RudVpbFJ6dCVo5dw56':
            case 'price_1P5PUfRudVpbFJ6d9xChtqtN':
              formData.subscription = 'subscription-intermediaire'
              subscriptionLocal = 'subscription-intermediaire'
              break
            case 'price_1P8GmJRudVpbFJ6dMhlX6t6x':
            case 'price_1P5PVFRudVpbFJ6dIKqjHecM':
              formData.subscription = 'subscription-fullaccess'
              subscriptionLocal = 'subscription-fullaccess'
              break
            default:
              formData.subscription = 'none'
              subscriptionLocal = 'none'
              break
          }

          fetch(`/api/user/metadata/${data[0].id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          })
            .then(async (response) => {
              const data = await response.json()

              const userData = JSON.parse(localStorage.getItem('user'))
              userData.subscription = subscriptionLocal
              userData.customer_stripe_id = customerId

              localStorage.setItem('user', JSON.stringify(userData))

              toast.success('Abonnement activé avec succès !')
              navigate('/dashboard')
            })
            .catch((error) => {
              console.log(error)
            })
        })
      }
    })
  }, [])

  return <div></div>
}

export default SubscriptionSuccess
