import React, { Component } from 'react'
import './Slider.css'
import bitcoin from '../../assets/bitcoin-btc-logo.png'
import ethereum from '../../assets/ethereum-eth-logo.png'
import solana from '../../assets/solana-sol-logo.png'
import bnb from '../../assets/bnb-bnb-logo.png'
import cardano from '../../assets/cardano-ada-logo.png'
import chainlink from '../../assets/chainlink-link-logo.png'
import dogecoin from '../../assets/dogecoin-doge-logo.png'
import shiba from '../../assets/shiba-inu-shib-logo.png'
import uniswap from '../../assets/uniswap-uni-logo.png'
import polygon from '../../assets/polygon-matic-logo.png'

export default class Slider extends Component {
  render() {
    return (
      <div className="slider">
        <div className="logos">
          <div className="logo-slide">
            <div>
              <img src={bitcoin} alt="bitcoin-logo"></img>
              <p>Bitcoin</p>
            </div>
            <div>
              <img src={ethereum} alt="ethereum-logo"></img>
              <p>Ethereum</p>
            </div>
            <div>
              <img src={solana} alt="solana-logo"></img>
              <p>Solana</p>
            </div>
            <div>
              <img src={bnb} alt="bnb-logo"></img>
              <p>BNB</p>
            </div>
            <div>
              <img src={cardano} alt="cardano-logo"></img>
              <p>Cardano</p>
            </div>
            <div>
              <img src={chainlink} alt="chainlink-logo"></img>
              <p>ChainLink</p>
            </div>
            <div>
              <img src={dogecoin} alt="dogecoin-logo"></img>
              <p>Dogecoin</p>
            </div>
            <div>
              <img src={shiba} alt="shiba-logo"></img>
              <p>Shiba</p>
            </div>
            <div>
              <img src={uniswap} alt="uniswap-logo"></img>
              <p>UniSwap</p>
            </div>
            <div>
              <img src={polygon} alt="polygon-logo"></img>
              <p>Polygon</p>
            </div>
          </div>
          <div className="logo-slide">
            <div>
              <img src={bitcoin} alt="bitcoin-logo"></img>
              <p>Bitcoin</p>
            </div>
            <div>
              <img src={ethereum} alt="ethereum-logo"></img>
              <p>Ethereum</p>
            </div>
            <div>
              <img src={solana} alt="solana-logo"></img>
              <p>Solana</p>
            </div>
            <div>
              <img src={bnb} alt="bnb-logo"></img>
              <p>BNB</p>
            </div>
            <div>
              <img src={cardano} alt="cardano-logo"></img>
              <p>Cardano</p>
            </div>
            <div>
              <img src={chainlink} alt="chainlink-logo"></img>
              <p>ChainLink</p>
            </div>
            <div>
              <img src={dogecoin} alt="dogecoin-logo"></img>
              <p>Dogecoin</p>
            </div>
            <div>
              <img src={shiba} alt="shiba-logo"></img>
              <p>Shiba</p>
            </div>
            <div>
              <img src={uniswap} alt="uniswap-logo"></img>
              <p>UniSwap</p>
            </div>
            <div>
              <img src={polygon} alt="polygon-logo"></img>
              <p>Polygon</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
