import React, { useEffect, useState } from 'react'
import './LandingPageDashboard.css'
import { IoWalletOutline } from 'react-icons/io5'
import { FaArrowTrendUp } from 'react-icons/fa6'
import { FaFire } from 'react-icons/fa'
import { GoArrowUpRight } from 'react-icons/go'
import { GoArrowDownLeft } from 'react-icons/go'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import TopNavbarDashboard from '../../TopNavbarDashboard/TopNavbarDashboard'
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines'

const LandingPageDashboard = ({ handleIndexChange }) => {
  const [lowCaps, setLowCaps] = useState(null)
  const [trendingsCaps, setTrendings] = useState(null)

  useEffect(() => {
    fetch('/api/json-low-caps', {
      method: 'GET',
    })
      .then(async (response) => {
        const data = await response.json()

        setLowCaps(data)
      })
      .catch((error) => {})

    fetch('/api/trendings-data', {
      method: 'GET',
    })
      .then(async (response) => {
        const data = await response.json()

        setTrendings(data)
      })
      .catch((error) => {})
  }, [])

  function formatMarketCap(marketCap) {
    if (marketCap >= 1000000000000) {
      return (marketCap / 1000000000000).toFixed(2) + 'T'
    } else if (marketCap >= 1000000000) {
      return (marketCap / 1000000000).toFixed(2) + 'B'
    } else if (marketCap >= 1000000) {
      return (marketCap / 1000000).toFixed(2) + 'M'
    } else if (marketCap >= 1000) {
      return (marketCap / 1000).toFixed(2) + 'K'
    } else {
      return marketCap.toFixed(2)
    }
  }

  return (
    <div className="landing-page-dashboard">
      <TopNavbarDashboard text="Dashboard" handleIndexChange={handleIndexChange} />
      <div className="landing-page-content">
        <div className="low-caps-content">
          <h2>Low Caps</h2>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Monnaie</th>
                  <th>Prix</th>
                  <th>MarketCap</th>
                  <th>Volume 24h</th>
                  <th>24h Change</th>
                </tr>
              </thead>
              <tbody>
                {lowCaps
                  ? lowCaps.map((lowCap, index) => (
                      <tr key={index}>
                        <td className="symbol-content">
                          <img src={lowCap.logo}></img>
                          {lowCap.symbol.toUpperCase()}
                        </td>
                        <td>{lowCap.current_price}€</td>
                        <td>{formatMarketCap(lowCap.market_cap)}</td>
                        <td>{formatMarketCap(lowCap.total_volume)}</td>
                        {lowCap.price_change_percentage_24h > 0 ? (
                          <td className="positive-change">
                            <div>
                              <GoArrowUpRight />
                            </div>
                            +{lowCap.price_change_percentage_24h.toFixed(2)}%
                          </td>
                        ) : (
                          <td className="negative-change">
                            <div>
                              <GoArrowDownLeft />
                            </div>
                            {lowCap.price_change_percentage_24h.toFixed(2)}%
                          </td>
                        )}
                      </tr>
                    ))
                  : ' '}
              </tbody>
            </table>
          </div>
        </div>
        <div className="trendings-content">
          <h2>Trendings</h2>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Monnaie</th>
                  <th>Prix</th>
                  <th>MarketCap</th>
                  <th>Volume 24h</th>
                  <th>7 Days Change</th>
                  <th>7 Days Market</th>
                  <th>24h Change</th>
                </tr>
              </thead>
              <tbody>
                {trendingsCaps
                  ? trendingsCaps.map((lowCap, index) => (
                      <tr key={index}>
                        <td className="symbol-content">
                          <img src={lowCap.logo}></img>
                          {lowCap.symbol.toUpperCase()}
                        </td>
                        <td>{lowCap.current_price}€</td>
                        <td>{formatMarketCap(lowCap.market_cap)}</td>
                        <td>{formatMarketCap(lowCap.total_volume)}</td>
                        {lowCap.price_change_percentage_7d > 0 ? (
                          <td className="positive-change">
                            <div>
                              <GoArrowUpRight />
                            </div>
                            +{lowCap.price_change_percentage_7d.toFixed(2)}%
                          </td>
                        ) : (
                          <td className="negative-change">
                            <div>
                              <GoArrowDownLeft />
                            </div>
                            {lowCap.price_change_percentage_7d.toFixed(2)}%
                          </td>
                        )}
                        {lowCap.price_change_percentage_7d > 0 ? (
                          <td className="sparklines">
                            <Sparklines data={lowCap.sparkline} width={100} height={20}>
                              <SparklinesLine color="rgba(0, 188, 120, 1)" style={{ fill: 'none' }} />
                            </Sparklines>
                          </td>
                        ) : (
                          <td className="sparklines">
                            <Sparklines data={lowCap.sparkline} width={100} height={20}>
                              <SparklinesLine color="rgba(255, 80, 101, 1)" style={{ fill: 'none' }} />
                            </Sparklines>
                          </td>
                        )}
                        {lowCap.price_change_percentage_24h > 0 ? (
                          <td className="positive-change">
                            <div>
                              <GoArrowUpRight />
                            </div>
                            +{lowCap.price_change_percentage_24h.toFixed(2)}%
                          </td>
                        ) : (
                          <td className="negative-change">
                            <div>
                              <GoArrowDownLeft />
                            </div>
                            {lowCap.price_change_percentage_24h.toFixed(2)}%
                          </td>
                        )}
                      </tr>
                    ))
                  : ' '}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="footer-mobile"></div>
    </div>
  )
}

export default LandingPageDashboard
