import React, { useEffect, useState } from 'react'
import Home from './components/Home/Home'
import Dashboard from './components/Dashboard/Dashboard'
import UnknownPage from './components/ErrorPage/UnknownPage'
import '@fontsource/poppins'
import '@fontsource/inter'
import '@fontsource/roboto'
import '@fontsource/inter/100.css'
import '@fontsource/inter/200.css'
import '@fontsource/inter/300.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'
import '@fontsource/inter/800.css'
import '@fontsource/inter/900.css'
import '@fontsource/roboto/100.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource/roboto/900.css'
import '@fontsource/poppins/100.css'
import '@fontsource/poppins/200.css'
import '@fontsource/poppins/300.css'
import '@fontsource/poppins/400.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/600.css'
import '@fontsource/poppins/700.css'
import '@fontsource/poppins/800.css'
import '@fontsource/poppins/900.css'
import useLocalStorage from 'use-local-storage'
import './App.css'
import { Route, Routes } from 'react-router-dom'
import SubscriptionSuccess from './components/SubscriptionHandle/SubscriptionSuccess'
import SubscriptionFailed from './components/SubscriptionHandle/SubscriptionFailed'
import ValidateEmail from './components/ValidateEmail/ValidateEmail'
import ResetPassword from './components/Password/ResetPassword'
import Administration from './components/Administration/Administration'
import { SkeletonTheme } from 'react-loading-skeleton'
import Login from './components/Login/Login'
import Register from './components/Register/Register'
import { Toaster } from 'sonner'
import PasswordForgotten from './components/Password/PasswordForgotten'
import Closeout from './components/DashboardPages/Closeout/Closeout'
import Checkout from './components/Checkout/Checkout'

function App() {
  const [isDark, setIsDark] = useLocalStorage('darkmode', false)
  const [activeIndex, setActiveIndex] = useState(0)

  const handleIndexChange = (index) => {
    setActiveIndex(index)
  }

  return (
    <>
      <div data-theme={isDark ? 'dark' : 'light'}>
        <Toaster position="top-left" className="toaster-app" expand={false} richColors />
        <SkeletonTheme baseColor="#313131" highlightColor="#525252">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/register" element={<Register />} />
            <Route
              exact
              path="/dashboard"
              element={<Dashboard handleIndexChange={handleIndexChange} activeIndex={activeIndex} />}
            />
            <Route exact path="/subscription-success" element={<SubscriptionSuccess />} />
            <Route exact path="/subscription-failed" element={<SubscriptionFailed />} />
            <Route exact path="/send-reset" element={<PasswordForgotten />} />
            <Route exact path="/validate-email" element={<ValidateEmail />} />
            <Route exact path="/reset-password" element={<ResetPassword />} />
            <Route exact path="/dashboard/admin" element={<Administration />} />
            <Route exact path="/checkout" element={<Checkout />} />
            <Route exact path="*" element={<UnknownPage />} />
          </Routes>
        </SkeletonTheme>
      </div>
    </>
  )
}

export default App
