import React, { Component } from 'react'
import pana from '../../assets/pana.png'
import './Hero.css'
import { GoArrowRight } from 'react-icons/go'

export default class Hero extends Component {
  render() {
    return (
      <div className="hero" id="Hero">
        <div className="text-content">
          <div>
            <h1 className="title-hero">
              COINFINDER
              <br />
              <span className="underline-description">VOTRE GUIDE CRYPTO</span>
            </h1>
            <p className="description">
              Découvre le monde des crypto-monnaies avec notre guide. Trouve des infos, des analyses et des conseils
              <br></br> pour mieux comprendre. Que tu sois débutant ou expert, notre plateforme t'aide à explorer ce
              monde fascinant.
            </p>
          </div>
          <div className="discover-content">
            <a className="discover" href="#Subscription">
              Explorez Maintenant <GoArrowRight className="arrow-right" />
            </a>
          </div>
        </div>
        <img src={pana} alt="" className="image-hero"></img>
      </div>
    )
  }
}
