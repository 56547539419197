import React from 'react'
import './UnknownPage.css'
import { useNavigate } from 'react-router-dom';

const UnknownPage = () => {
  const navigate = useNavigate();

  const nagivateHome = () => {
    navigate("/");
  }

  return (
    <div className='error-page'>
      <div className='error-container'>
        <h1>Erreur 404</h1>
        <p>Cette page n'existe pas</p>
        <a onClick={nagivateHome}>Revenir à l'accueil</a>
      </div>
    </div>
  )
}

export default UnknownPage;